import { createContext } from 'react'

const PlayerContext = createContext({
  activeSpotifyUrl: null, setActiveSpotifyUrl: () => {},
  activeAppleMusicUrl: null, setActiveAppleMusicUrl: () => {},
  activeYoutubeUrl: null, setActiveYoutubeUrl: () => {},
  activeSoundCloudUrl: null, setActiveSoundCloudUrl: () => {},
})

export default PlayerContext
