import React from 'react'

const SoundCloudPlayer = ({ soundCloudUrl }) => {
  if(!soundCloudUrl) return null

  return (
    <iframe
      width="100%"
      height="300"
      scrolling="no"
      frameBorder="no"
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
      allow="autoplay *; encrypted-media *; clipboard-write"
      src={soundCloudUrl}
    />
    )
}

export default SoundCloudPlayer
