import React from 'react'
import MusicBrowser from '../ui/MusicBrowser'
import FaIcon from '../../helpers/fa_icon'

const AddSongControls = ({ onChange, uploadPath, disabled, position }) => (
  <div className="add-song-button-wrapper">
    <MusicBrowser
      onChange={song => onChange(song, position)}
      label={<FaIcon icon="plus" text="Add Song" />}
      title="Add Song"
      editExtendedAttributes={true}
      disabled={disabled}
    />
    { " " }
    <a href={uploadPath} className={`btn btn-primary ${disabled ? 'disabled' : ''}`}>
      <FaIcon icon="upload" />
      { " " }
      Bulk Upload
    </a>
  </div>
)

export default AddSongControls
