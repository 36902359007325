  // const soundCloudIframeUrl = soundCloudUrl && `https://w.soundcloud.com/player/?url=${encodeURIComponent(soundCloudUrl)}&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`

export default function extractSoundCloudEmbedUrl(url) {
  if(url.match(/https:\/\/soundcloud\.com\/.*/) && url.match(/url=/)) {
    return url
  }
  else if(url.match(/https:\/\/soundcloud\.com\/.*/)) {
    return `https://w.soundcloud.com/player/?url=${encodeURIComponent(url)}&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`
  }
  else {
    return null
  }
}

