import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import FaIcon from '../../../../helpers/fa_icon'
import PlayerContext from './PlayerContext'
import SpotifyPlayer from './SpotifyPlayer'
import AppleMusicPlayer from './AppleMusicPlayer'
import YoutubePlayer from './YoutubePlayer'
import SoundCloudPlayer from './SoundCloudPlayer'

const FloatingPlayer = ({ children }) => {
  const [show, setShow] = useState(false)
  const [activeSpotifyUrl, _setActiveSpotifyUrl] = useState(null)
  const [activeAppleMusicUrl, _setActiveAppleMusicUrl] = useState(null)
  const [activeYoutubeUrl, _setActiveYoutubeUrl] = useState(null)
  const [activeSoundCloudUrl, _setActiveSoundCloudUrl] = useState(null)

  useEffect(() => {
    if (activeSpotifyUrl || activeAppleMusicUrl || activeYoutubeUrl || activeSoundCloudUrl) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [activeSpotifyUrl, activeAppleMusicUrl, activeYoutubeUrl, activeSoundCloudUrl])

  const handleClose = (e) => {
    e.preventDefault()
    setActiveSpotifyUrl(null)
    setActiveAppleMusicUrl(null)
    setActiveYoutubeUrl(null)
    setActiveSoundCloudUrl(null)
    setShow(false)
  }

  // When an active URL is set, we want to make sure all other URLs are cleared out
  function setActiveSpotifyUrl(url) {
    _setActiveSpotifyUrl(url)
    _setActiveAppleMusicUrl(null)
    _setActiveYoutubeUrl(null)
    _setActiveSoundCloudUrl(null)
  }

  function setActiveAppleMusicUrl(url) {
    _setActiveAppleMusicUrl(url)
    _setActiveSpotifyUrl(null)
    _setActiveYoutubeUrl(null)
    _setActiveSoundCloudUrl(null)
  }

  function setActiveYoutubeUrl(url) {
    _setActiveYoutubeUrl(url)
    _setActiveSpotifyUrl(null)
    _setActiveAppleMusicUrl(null)
    _setActiveSoundCloudUrl(null)
  }

  function setActiveSoundCloudUrl(url) {
    _setActiveSoundCloudUrl(url)
    _setActiveSpotifyUrl(null)
    _setActiveAppleMusicUrl(null)
    _setActiveYoutubeUrl(null)
  }

  const playerContextValue = {
    activeSpotifyUrl, setActiveSpotifyUrl,
    activeAppleMusicUrl, setActiveAppleMusicUrl,
    activeYoutubeUrl, setActiveYoutubeUrl,
    activeSoundCloudUrl, setActiveSoundCloudUrl
  }

  return (
    <PlayerContext.Provider value={playerContextValue}>
      { children }
      {createPortal(
        <div className="cc-floating-player" style={{ display: show ? 'block' : 'none' }}>
          <div className="cc-floating-player-header">
            <a href="#" onClick={handleClose} className="cc-floating-player-close"><FaIcon icon="times" /></a>
          </div>
          <div className="cc-floating-player-content">
            { activeSpotifyUrl && <SpotifyPlayer spotifyUrl={activeSpotifyUrl} /> }
            { activeAppleMusicUrl && <AppleMusicPlayer appleMusicUrl={activeAppleMusicUrl} />  }
            { activeYoutubeUrl && <YoutubePlayer youtubeUrl={activeYoutubeUrl} />  }
            { activeSoundCloudUrl && <SoundCloudPlayer soundCloudUrl={activeSoundCloudUrl} />  }
          </div>
        </div>
        , document.querySelector('#content-container'))}
    </PlayerContext.Provider>
  )
}

export default FloatingPlayer
