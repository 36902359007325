import React from 'react'

const AppleMusicPlayer = ({ appleMusicUrl }) => {
  if(!appleMusicUrl) return null

  return (
    <iframe
      src={appleMusicUrl}
      width="100%"
      height="450"
      title="Media player"
      id="embedPlayer"
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
      allow="autoplay *; encrypted-media *; clipboard-write"
      style={{ border: '0px', borderRadius: '12px', width: '100%', height: '450px', maxWidth: '658px' }}
      frameBorder="0"
      allowtransparency="true"
    />
    )
}

export default AppleMusicPlayer
