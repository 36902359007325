import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import SearchBox from './components/SearchBox'
import BrandContext from './contexts/BrandContext'
import './index.scss'


const GlobalSearch = ({ apiKey, brands }) => {
  const [showSearchModal, setShowSearchModal] = useState(false)

  // Bind Command-K to open the search modal
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'k' && e.metaKey) {
        e.preventDefault()
        showGlobalSearch()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  // Listen for the global search event
  useEffect(() => {
    // Look for records with the "data-toggle-global-search" attribute
    document.querySelectorAll('[data-toggle-global-search]').forEach((el) => {
      el.addEventListener('click', showGlobalSearch)
    })

    return () => {
      document.querySelectorAll('[data-toggle-global-search]').forEach((el) => {
        el.removeEventListener('click', showGlobalSearch)
      })
    }
  }, [])

  function showGlobalSearch() {
    setShowSearchModal(true)
  }

  return (
    <div className="global-search-widget">
      <Modal
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        size="lg"
      >
        <Modal.Header closeButton className="d-sm-none" />
        <Modal.Body>
          <BrandContext.Provider value={{ brands }}>
            <SearchBox apiKey={apiKey} />
          </BrandContext.Provider>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default GlobalSearch
