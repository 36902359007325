import React from 'react'
import SearchResultListConfirmedBookings from './SearchResultListConfirmedBookings'
import SearchResultListProposals from './SearchResultListProposals'
import SearchResultListMiniSessions from './SearchResultListMiniSessions'
import SearchResultListLeads from './SearchResultListLeads'
import SearchResultListUsers from './SearchResultListUsers'
import SearchResultListAppointments from './SearchResultListAppointments'

const SearchResultList = ({ searchResults, searchValue }) => {
  const { bookings, proposals, miniSessions, leads, users, appointments } = searchResults

  if(!anyResults(searchResults)) {
    return (
      <div className="global-search-result-list mt-3 text-center">
        <p>No results found</p>
      </div>
    )
  }

  return (
    <div className="global-search-result-list mt-3">
      <SearchResultListConfirmedBookings bookings={bookings} searchValue={searchValue} />
      <SearchResultListProposals proposals={proposals} searchValue={searchValue} />
      <SearchResultListMiniSessions miniSessions={miniSessions} searchValue={searchValue} />
      <SearchResultListLeads leads={leads} searchValue={searchValue} />
      <SearchResultListAppointments appointments={appointments} searchValue={searchValue} />
      <SearchResultListUsers users={users} searchValue={searchValue} />
    </div>
  )
}

function anyResults(searchResults) {
  return Object.values(searchResults).some(result => result.data.length > 0)
}


export default SearchResultList
