import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import TimeSlotContainer from './TimeSlotContainer'
import CartPreview from './CartPreview'
import { format } from 'date-fns'

@inject("store")
@observer
export default class Unavailable extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }


  render() {
    const { store } = this.props

    return (
      <div>
        <div className="time-chooser-date-header">
          <div>{ format(store.selectedDate, "MMMM do, yyyy") }</div>
        </div>
        <div className="time-slot-container">
          <div dangerouslySetInnerHTML={{__html: store.unavailableHtml}} />
        </div>
      </div>
    )
  }
}
