import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Nav, Button, Modal, InputGroup, FormGroup, FormControl } from 'react-bootstrap'
import CurrentSongDisplay from './CurrentSongDisplay'
import SuggestionSearch from './SuggestionSearch'
import CustomSong from './CustomSong'
import client from '../../../helpers/client'


const ALL_PROVIDERS = [
  { value: "current", label: "Current Song"},
  { value: "suggestions", label: "Suggestions" },
  { value: "custom", label: "Add Custom Song" },
]

function availableProvidersFor({ currentSong, suggestionListId, allowCustomSongEntry }) {
  return ALL_PROVIDERS.filter(provider => {
    if (provider.value == "current") {
      return !!currentSong
    } else if (provider.value == "suggestions") {
      return !!suggestionListId
    } else if(provider.value == "custom") {
      if(suggestionListId && !allowCustomSongEntry) {
        return false
      }
      return true
    } else {
      return true
    }
  })
}

const MusicBrowser = ({ label, title, disabled, onChange, onRemove, currentSong, editExtendedAttributes, suggestionListId, allowCustomSongEntry }) => {
  const availableProviders = useMemo(() => availableProvidersFor({ currentSong, suggestionListId, allowCustomSongEntry }), [currentSong, suggestionListId, allowCustomSongEntry])

  const [ provider, setProvider ] = useState(availableProviders[0].value)
  const [ showSongPicker, setShowSongPicker ] = useState(false)
  const [ searchText, setSearchText ] = useState("")

  function handleSongSelected(song) {
    // Only send the song if it is not blank.  If it is blank, we just close
    // the modal.
    if(song && (song.name || song.artistName || song.albumName || song.songNotes)) {
      onChange(song)
    }
    setShowSongPicker(false)
  }

  function handleSongRemoved(e) {
    e.preventDefault()

    if(onRemove) {
      onRemove()
    }
    else {
      onChange({ id: null, name: null, artistName: null, albumName: null, songNotes: null })
    }

    setShowSongPicker(false)
  }


  return (
    <>
      <Button onClick={() => setShowSongPicker(true)} disabled={disabled}>{ label }</Button>
      <Modal show={showSongPicker} onHide={() => setShowSongPicker(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{ title || "Search Songs" }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { availableProviders.length > 1 &&
            <div style={styles.navWrapper}>
              <Nav variant="pills" activeKey={provider} onSelect={provider => setProvider(provider)}>
                { availableProviders.map(provider => (
                  <Nav.Item key={provider.value}>
                    <Nav.Link eventKey={provider.value}>
                      {provider.label}
                    </Nav.Link>
                  </Nav.Item>
                )) }
              </Nav>
            </div>
          }
          { provider == "current" &&
          <div>
            <CurrentSongDisplay
              currentSong={currentSong}
              onSelect={handleSongSelected}
              editExtendedAttributes={editExtendedAttributes}
              suggestionListId={suggestionListId}
            />
            { handleSongRemoved && 
              <div className="text-end mt-1">
                <a href="#" onClick={handleSongRemoved} className="text-danger">Remove Song</a>
              </div>
            }
          </div>
          }
          { provider == "suggestions" &&
            <SuggestionSearch
              searchText={searchText}
              setSearchText={setSearchText}
              onSelect={handleSongSelected}
              suggestionListId={suggestionListId}
            />
          }
          { provider == "custom" &&
            <CustomSong onSelect={handleSongSelected} editExtendedAttributes={editExtendedAttributes} />
          }
        </Modal.Body>
      </Modal>
    </>

  )
}

const styles = {
  navWrapper: {
    marginBottom: 20
  }
}

export default MusicBrowser
