import React, { useState, useEffect } from 'react'
import { FormControl, Button } from 'react-bootstrap'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SongPreview from '../ui/SongPreview'
import { FloatingPlayer } from '../ui/SongPreview'
import AddSongControls from './AddSongControls'
import FaIcon from '../../helpers/fa_icon'
import useDebounce from '../../helpers/useDebounce'
import client from '../../helpers/client'
import './style.scss'


const SuggestionListEditor = ({ apiKey, suggestionListId, downloadPath, maxItems, uploadPath, title: initialTitle, description: initialDescription, data }) => {
  const [ loading, setLoading ] = useState(false)
  const [ searchText, setSearchText ] = useState("")
  const debouncedSearchText = useDebounce(searchText, 500)
  const [ notification, setNotification ] = useState("")
  const [ showTitleEditor, setShowTitleEditor ] = useState(false)
  const [ showDescriptionEditor, setShowDescriptionEditor ] = useState(false)
  const [ title, setTitle ] = useState(initialTitle || '')
  const [ description, setDescription ] = useState(initialDescription || '')
  const [ list, setList ] = useState(data || [])
  const [ previousList, setPreviousList ] = useState(list)

  const maxSongLimitReached = list.length >= maxItems

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => setNotification(""), 3000)
      return () => clearTimeout(timeout)
    }
  }, [notification])


  function handleAddSong (song, position = "bottom") {
    const newList = list.filter(s => s.id != song.id) // don't add duplicates
    if(position == "top") {
      newList.unshift(song)
    }
    else {
      newList.push(song)
    }
    setList(newList)
  }

  function handleUpdateSong(song) {
    const newList = list.map(s => {
      if(s.id == song.id) {
        return song
      }
      else {
        return s
      }
    })
    setList(newList)
  }


  function handleRemoveSong (index) {
    const newList = [...list]
    newList.splice(index, 1)
    setList(newList)
  }

  async function persistList (e, showNotification = true) {
    e.preventDefault()

    setLoading(true)
    setShowTitleEditor(false)
    setShowDescriptionEditor(false)
    try {
      const url = `/admin/suggestion_lists/${suggestionListId}.json`
      const response = await client.put(url, { title, description, data: JSON.stringify(list) })

      if(showNotification) {
        window.scrollTo(0, 0)
        setNotification("Your changes have been saved")
      }
      setPreviousList(list)
    }
    catch(e) {
      console.log(e)
      alert("There was an error saving your changes")
    }
    setLoading(false)
  }

  async function finishEditingTitle (e) {
    e.preventDefault()
    setShowTitleEditor(false)
    persistList(e, false)
  }

  async function finishEditingDescription (e) {
    e.preventDefault()
    setShowDescriptionEditor(false)
    persistList(e, false)
  }

  const handleDragEnd = ({ draggableId, source, destination }) => {
    if (!destination) return
    const newList = [...list]
    const [removed] = newList.splice(source.index, 1)
    newList.splice(destination.index, 0, removed)
    setList(newList)
  }

  function handleShowTitleEditor (e) {
    e.preventDefault()
    setShowTitleEditor(true)
  }

  function handleShowDescriptionEditor (e) {
    e.preventDefault()
    setShowDescriptionEditor(true)
  }

  const hasUnsavedChanges = (JSON.stringify(list) != JSON.stringify(previousList))

  const visibleList = list.filter(song => {
    if(!debouncedSearchText) return true

    const songSearchText = [song.name, song.artistName, song.albumName].join(" ").toLowerCase()
    return songSearchText.includes(debouncedSearchText.toLowerCase())
  })

  const isDragDisabled = !!debouncedSearchText

  return (
    <div className="suggestion-list-editor">
      <FloatingPlayer>
        { notification && <div className="text-center alert fade show alert-success">{ notification }</div> }
        { maxSongLimitReached && <div className="text-center alert alert-warning">Maximum of {maxItems} items reached</div> }
        <h1>
          { showTitleEditor ?
            <form onSubmit={finishEditingTitle}>
              <input
                type="text"
                className="title-input"
                value={title}
                onChange={e => setTitle(e.target.value)}
                autoFocus
                onBlur={finishEditingTitle}
              />
            </form>
            :
            <>
              { title }
              <a href="#" onClick={handleShowTitleEditor} className="title-icon edit-title"><FaIcon icon="pencil-square-o" /></a>
              <a href={downloadPath} className="title-icon download"><FaIcon icon="download" /></a>
            </>
          }
        </h1>
        {
          showDescriptionEditor ?
            <form onSubmit={finishEditingDescription}>
              <input
                type="text"
                className="description-input"
                value={description}
                onChange={e => setDescription(e.target.value)}
                onBlur={finishEditingDescription}
                autoFocus
              />
            </form>
            :
            <p>
              { description ? <span>{ description }</span> : <span className="text-muted">Optionally add a short description here</span> }
              <a href="#" onClick={handleShowDescriptionEditor} className="description-icon edit-description"><FaIcon icon="pencil-square-o" /></a>
            </p>
        }

        <div className="toolbar-wrapper">
          <AddSongControls uploadPath={uploadPath} onChange={handleAddSong} position="top" disabled={maxSongLimitReached} />

          <div className="search-wrapper">
            <FormControl
              type="search"
              placeholder="Search for matching songs"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="suggestion-list-droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                { visibleList.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={`draggable-${item.id}`}
                    index={index}
                    isDragDisabled={isDragDisabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div key={index}>
                          <div className="song-row">
                            <div className="song-preview">
                              <SongPreview
                                {...item}
                                onUpdate={handleUpdateSong}
                                onRemove={handleRemoveSong}
                                editExtendedAttributes={true}

                              />
                            </div>
                            <div className="controls">
                              <a href="#" onClick={e => {
                                e.preventDefault()
                                handleRemoveSong(index)}
                                }>
                                <FaIcon icon="times" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                { provided.placeholder }
              </div>
            )}
          </Droppable>
        </DragDropContext>
        { list.length > 0 && <AddSongControls uploadPath={uploadPath} onChange={handleAddSong} position="bottom" disabled={maxSongLimitReached}/> }
        <div className="submit-button-wrapper">
          <Button onClick={persistList} variant='primary' size='lg' disabled={loading || !hasUnsavedChanges} className="w-100">
            { loading ? "Saving..." : "Save Changes" }
          </Button>
        </div>
      </FloatingPlayer>
    </div>
  )
}

export default SuggestionListEditor
