import { autorun, observable, action, computed } from 'mobx'
import { toJS } from 'mobx'
import { create, persist } from 'mobx-persist'
import client from '../../../helpers/client'
import parseTime from '../../../helpers/parseTime'
import { asNormalizedMaps, asNormalizedArrays } from '../../../helpers/json-normalizer'
import { persistToUrl, restoreFromUrl } from '../util/persistentUrlState'
import getAlways from '../util/getAlways'
import enumerateDaysBetweenDates from '../util/enumerateDaysBetweenDates'
import moment from 'moment'
import groupBy from '../util/groupBy'
import eventBookingShouldBeGrouped from '../util/eventBookingShouldBeGrouped'
import extractAttributes from '../util/extractAttributes'

const cachePeriod = "month"

class FranchiseCalendarStore {
  @persist @observable showEventBookings = true
  @persist @observable showLeads = false
  @persist @observable showMiniSessions = true
  @persist @observable showFranchiseBlockoutDates = false
  @persist @observable showStaffBlockoutDates = false
  @persist @observable showProposals = true
  @persist @observable showConfirmed = true
  @persist @observable showCanceled = true
  @persist @observable showSynced = true
  @persist @observable showAppointments = true

  @observable view = "month"
  @observable loading = false
  @observable eventBookings = new Map()
  @observable miniSessions = new Map()
  @observable miniSessionSlots = new Map()
  @observable leadBookings = new Map()
  @observable syncedEvents = new Map()
  @observable appointments = new Map()
  @observable franchiseBlockoutDateRanges = new Map()
  @observable franchiseDailyAvailability = new Map()
  @observable staffBlockoutDateRanges = new Map()
  @observable staff = new Map()
  @observable _date = restoreFromUrl({ param: "date", type: "date" }) || new Date()
  @observable _brandId = restoreFromUrl({ param: "brandId", type: "string" }) || ""
  @observable fetchedUrls = new Map()

  constructor({
    apiKey,
    staffId,
    enableLeads,
    enableMiniSessions,
    showBrands,
    brands,
    enableSynced,
    enableAppointments,
    enableConfirmed,
    enableProposals,
    enableCancelled,
    enableStaffOff,
    enableBlockoutDates,
    hideStatusSelector,
    enableGrouping,
  }) {
    this.apiKey = apiKey
    this.staffId = staffId
    this.enableLeads = enableLeads
    this.enableMiniSessions = enableMiniSessions
    this.showBrands = showBrands
    this.brands = brands
    this.enableSynced = enableSynced
    this.enableAppointments = enableAppointments
    this.enableConfirmed = enableConfirmed
    this.enableProposals = enableProposals
    this.enableCancelled = enableCancelled
    this.enableStaffOff = enableStaffOff
    this.enableBlockoutDates = enableBlockoutDates
    this.hideStatusSelector = hideStatusSelector
    this.enableGrouping = enableGrouping
  }

  async refreshEvents(date = this.date) {
    this.loading = true
    if((this.enableConfirmed && this.showConfirmed) ||
      (this.enableProposals && this.showProposals) ||
      (this.enableCancelled && this.showCanceled)
    ) {
      await this.fetchEventBookings(date)
    }
    if(this.enableLeads && this.showLeads) {
      await this.fetchLeadBookings(date)
    }
    if(this.enableMiniSessions && this.showMiniSessions) {
      await this.fetchMiniSessions(date)
    }
    if(this.enableBlockoutDates && (this.showFranchiseBlockoutDates || this.hideStatusSelector)) {
      await this.fetchFranchiseBlockoutDateRanges(date)
    }
    if(this.enableStaffOff && this.showStaffBlockoutDates) {
      await this.fetchStaffBlockoutDateRanges(date)
      await this.fetchStaff()
    }
    if(this.enableSynced && (this.showSynced || this.hideStatusSelector)) {
      await this.fetchGoogleCalendarEvents(date)
    }
    if(this.enableAppointments && this.showAppointments) {
      await this.fetchAppointments(date)
    }

    this.loading = false
  }

  @computed get calendarEvents() {
    let events = []

    if(this.showEventBookings) {
      events = [...events, ...this._visibleEventBookings]
    }

    if(this.enableLeads && this.showLeads) {
      events = [...events, ...this._visibleLeadBookings.values()]
    }

    if(this.enableBlockoutDates && (this.showFranchiseBlockoutDates || this.hideStatusSelector)) {
      events = [...events, ...this.daysFranchiseUnavailable, ...this.franchiseBlockoutDateRanges.values()]
    }

    if(this.enableMiniSessions && this.showMiniSessions) {
      events = [...events, ...this._visibleMiniSessions.values()]
    }

    if(this.showStaffBlockoutDates) {
      events = [...events, ...this.daysStaffUnavailable, ...this.staffBlockoutDateRanges.values()]
    }

    if(this.enableSynced && (this.showSynced || this.hideStatusSelector)) {
      events = [...events, ...this._visibleSyncedEvents.values()]
    }

    if(this.enableAppointments && this.showAppointments) {
      events = [...events, ...this._visibleAppointments.values()]
    }

    return events
  }

  @computed get _visibleEventBookings() {
    const results = Array.from(this.eventBookings.values())
      .filter(eventBooking => {
        const { type, status } = eventBooking
        const attributes = extractAttributes(eventBooking)
        const { canceled, postponed, expiredProposal } = attributes

        if(canceled || postponed || expiredProposal) {
          return this.showCanceled
        }
        else if(status === "proposal_date_open" || status === "proposal_date_reserved" || status === "proposal_awaiting_review") {
          return this.showProposals
        }
        else if(status === "awaiting_signature" || status == "confirmed") {
          return this.showConfirmed
        }
        return false
      })
    return results
  }

  @computed get date() {
    return this._date
  }

  set brandId(brandId) {
    this._brandId = brandId
    this.refreshEvents()
    // persistBrandIdToUrl(brandId)
    persistToUrl({ param: "brandId", type: "string", value: brandId })
  }

  @computed get brandId() {
    return this._brandId
  }

  set date(date) {
    this._date = date
    this.refreshEvents(date)
    // persistDateToUrl(date)
    persistToUrl({ param: "date", type: "date", value: date })
  }

  @computed get year() {
    return this.date.getFullYear()
  }

  set year(year) {
    let newDate = moment(this.date)
    newDate.year(year)
    this.date = newDate.toDate()
  }

  @computed get day() {
    return this.date.getDate()
  }

  set day(day) {
    let newDate = moment(this.date)
    newDate.date(day)
    this.date = newDate.toDate()
  }


  @computed get monthZeroIndexed() {
    return this.date.getMonth()
  }

  set monthZeroIndexed(month) {
    let newDate = moment(this.date)
    newDate.month(month)
    this.date = newDate.toDate()
  }

  @computed get week() {
    return moment(this.date).startOf("week").format("YYYY-MM-DD")
  }

  set week(newWeek) {
    const newDate = moment(newWeek).startOf("week").toDate();
    this.date = newDate
  }

  @computed get visibleDays() {
    switch(this.view) {
      case "month":
        // Note that our months show leading and trailing days in the calendar view
        const beginningOfMonth = moment(this.date).startOf("month").startOf("week")
        const endOfMonth = moment(this.date).endOf("month").endOf("week")
        return enumerateDaysBetweenDates(beginningOfMonth, endOfMonth)
      case "week":
        const beginningOfWeek = moment(this.date).startOf("week")
        const endOfWeek = moment(this.date).endOf("week")
        return enumerateDaysBetweenDates(beginningOfWeek, endOfWeek)
      case "day":
        return [this.date]
      default:
        throw new Error(`Unhandled view in visibleDays: ${this.view}`)
    }
  }

  @computed get daysFranchiseUnavailable() {
    return this.visibleDays
      .filter(date => {
        switch(date.getDay()) {
          case 0:
            return this.franchiseDailyAvailability.get("unavailableSunday")
          case 1:
            return this.franchiseDailyAvailability.get("unavailableMonday")
          case 2:
            return this.franchiseDailyAvailability.get("unavailableTuesday")
          case 3:
            return this.franchiseDailyAvailability.get("unavailableWednesday")
          case 4:
            return this.franchiseDailyAvailability.get("unavailableThursday")
          case 5:
            return this.franchiseDailyAvailability.get("unavailableFriday")
          case 6:
            return this.franchiseDailyAvailability.get("unavailableSaturday")
          default:
            return false
        }
      })
      .map(date => this._prepareFranchiseDayOff(date))
  }

  @computed get daysStaffUnavailable() {
    let events = []

    this.visibleDays.forEach(date => {
      this.staff.forEach(staff => {
        switch(date.getDay()) {
          case 0:
            if(staff.attributes.availableSundayDescription !== "Available") {
              events.push(this._prepareStaffDayOff(date, staff, staff.attributes.availableSundayDescription))
            }
            break
          case 1:
            if(staff.attributes.availableMondayDescription !== "Available") {
              events.push(this._prepareStaffDayOff(date, staff, staff.attributes.availableMondayDescription))
            }
            break
          case 2:
            if(staff.attributes.availableTuesdayDescription !== "Available") {
              events.push(this._prepareStaffDayOff(date, staff, staff.attributes.availableTuesdayDescription))
            }
            break
          case 3:
            if(staff.attributes.availableWednesdayDescription !== "Available") {
              events.push(this._prepareStaffDayOff(date, staff, staff.attributes.availableWednesdayDescription))
            }
            break
          case 4:
            if(staff.attributes.availableThursdayDescription !== "Available") {
              events.push(this._prepareStaffDayOff(date, staff, staff.attributes.availableThursdayDescription))
            }
            break
          case 5:
            if(staff.attributes.availableFridayDescription !== "Available") {
              events.push(this._prepareStaffDayOff(date, staff, staff.attributes.availableFridayDescription))
            }
            break
          case 6:
            if(staff.attributes.availableSaturdayDescription !== "Available") {
              events.push(this._prepareStaffDayOff(date, staff, staff.attributes.availableSaturdayDescription))
            }
            break
          default:
            break
        }
      })
    })

    return events
  }

  @computed get _visibleLeadBookings() {
    return Array.from(this.leadBookings.values())
  }

  @computed get _visibleMiniSessions() {
    return Array.from(this.miniSessions.values())
  }

  @computed get _visibleSyncedEvents() {
    return Array.from(this.syncedEvents.values())
  }

  @computed get _visibleAppointments() {
    return Array.from(this.appointments.values())
      .filter(appointment => {
        if(!appointment.attributes.canceled) {
          return true
        }
        else if (this.enableCancelled && this.showCanceled) {
          return true
        }
        else {
          return false
        }
      })
  }

  @action toggleShowMiniSessions() {
    if(!this.showMiniSessions) {
      this.fetchMiniSessions(this._date)
    }
    this.showMiniSessions = !this.showMiniSessions
  }

  @action toggleShowLeads() {
    if(!this.showLeads) {
      this.fetchLeadBookings(this._date)
    }
    this.showLeads = !this.showLeads
  }

  @action toggleShowSynced() {
    if(!this.showSynced) {
      this.fetchGoogleCalendarEvents(this._date)
    }
    this.showSynced = !this.showSynced
  }

  @action toggleShowAppointments() {
    if(!this.showAppointments) {
      this.fetchAppointments(this._date)
    }
    this.showAppointments = !this.showAppointments
  }

  @action toggleFranchiseBlockoutDates() {
    if(!this.showFranchiseBlockoutDates) {
      this.fetchFranchiseBlockoutDateRanges(this._date)
    }
    this.showFranchiseBlockoutDates = !this.showFranchiseBlockoutDates
  }

  @action toggleShowStaffBlockoutDates() {
    if(!this.showStaffBlockoutDates) {
      this.fetchStaffBlockoutDateRanges(this._date)
      this.fetchStaff()
    }
    this.showStaffBlockoutDates = !this.showStaffBlockoutDates
  }

  @action previous() {
    let newDate = moment(this.date)

    switch(this.view) {
      case "month":
        newDate.subtract(1, 'month')
        break;
      case "week":
        newDate.subtract(1, 'week')
        break;
      case "day":
        newDate.subtract(1, 'day')
        break
      default:
        throw new Error(`Unhandled view ${this.view}`)
    }
    this.date = newDate.toDate()
  }

  @action next() {
    let newDate = moment(this.date)

    switch(this.view) {
      case "month":
        newDate.add(1, 'month')
        break;
      case "week":
        newDate.add(1, 'week')
        break;
      case "day":
        newDate.add(1, 'day')
        break
      default:
        throw new Error(`Unhandled view ${this.view}`)
    }

    this.date = newDate.toDate()
  }

  @computed get daysInMonth() {
    const numberOfDays = moment(this.date).daysInMonth()
    let days = []
    for(let i = 1; i <= numberOfDays; i++) {
      days.push(i)
    }
    return days
  }

  @computed get weeksInYear() {
    const start = moment([this.year, 0, 1]).startOf("week")

    let weeks = []
    let current = moment(start)
    while(current.year() <= this.year) {
      const endOfWeek = moment(current).endOf("week")

      const label = current.month() === endOfWeek.month() ?
        `${current.format("MMM D")} - ${endOfWeek.format("D")}`
        :
        `${current.format("MMM D")} - ${endOfWeek.format("MMM D")}`
      const value = current.format("YYYY-MM-DD")

      weeks.push({ label, value })
      current = current.add(1, 'week')
    }

    return weeks
  }

  fetchEventBookings(date) {
    const startDate = moment(date).startOf(cachePeriod).format("YYYY-MM-DD")
    const endDate = moment(date).endOf(cachePeriod).format("YYYY-MM-DD")
    const url = `/api/v1/event_bookings`

    let params = {
      apiKey: this.apiKey,
      eventDateStart: startDate,
      eventDateEnd: endDate,
      hideSessions: true,
      per: 2000
    }
    if(this.staffId) {
      params.staffId = this.staffId
    }
    if(this.brandId) {
      params.brandId = this.brandId
    }

    return getAlways(url, { params })
      .then(response => {
        if(response) {
          const data = asNormalizedArrays(response.data)
          if(data.eventBooking) {
            if(this.enableGrouping) {
              const groupedEventBookings = groupBy(data.eventBooking, eventBookingShouldBeGrouped)
              const groupedEventBookingsWithIds = Object.values(groupedEventBookings)
                .map((record, index) => [index, this._prepareGroupedEventBooking(record)])

              this.eventBookings = new Map(groupedEventBookingsWithIds)
            }
            else {
              const convertedData = data.eventBooking.map(record => {
                const key = record.id
                const value = this._prepareEventBooking(record)
                return [key, value]
              })
              this.eventBookings = new Map(convertedData)
            }

          }
          else {
            this.eventBookings = new Map()
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  fetchLeadBookings(date) {
    const startDate = moment(date).startOf(cachePeriod).format("YYYY-MM-DD")
    const endDate = moment(date).endOf(cachePeriod).format("YYYY-MM-DD")
    const url = `/api/v1/lead_bookings`
    const params = {
      eventDateStart: startDate,
      eventDateEnd: endDate,
      apiKey: this.apiKey,
      per: 10000,
    }

    if(this.brandId) {
      params.brandId = this.brandId
    }

    return getAlways(url, { params })
      .then(response => {
        if(response) {
          const data = asNormalizedArrays(response.data)
          if(data.leadBooking) {
            const convertedData = data.leadBooking.map(record => {
              const key = ["leadBooking", record.attributes.id].join("|")
              const value = this._prepareLeadBooking(record)
              return [key, value]
            })
            this.leadBookings = new Map(convertedData)
          }
          else {
            this.leadBookings = new Map()
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  fetchGoogleCalendarEvents(date) {
    const startDate = moment(date).startOf(cachePeriod).format("YYYY-MM-DD")
    const endDate = moment(date).endOf(cachePeriod).format("YYYY-MM-DD")
    const url = `/api/v1/google_calendar_events`
    const params = {
      eventDateStart: startDate,
      eventDateEnd: endDate,
      apiKey: this.apiKey,
      per: 10000,
    }

    return getAlways(url, { params })
      .then(response => {
        if(response) {
          const data = asNormalizedArrays(response.data)
          if(data.googleCalendarEvent) {
            const convertedData = data.googleCalendarEvent.map(record => {
              const key = ["googleCalendarEvent", record.attributes.id].join("|")
              const value = this._prepareSyncEvent(record)
              return [key, value]
            })
            this.syncedEvents = new Map(convertedData)
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  fetchAppointments(date) {
    const startDate = moment(date).startOf(cachePeriod).format("YYYY-MM-DD")
    const endDate = moment(date).endOf(cachePeriod).format("YYYY-MM-DD")
    const url = `/api/v1/appointments`
    const params = {
      startDate,
      endDate,
      staffId: this.staffId,
      brandId: this.brandId,
      apiKey: this.apiKey,
      per: 10000,
    }

    return getAlways(url, { params })
      .then(response => {
        if(response) {
          const data = asNormalizedArrays(response.data)
          if(data.appointment) {
            const convertedData = data.appointment.map(record => {
              const key = ["appointment", record.attributes.id].join("|")
              const value = this._prepareAppointment(record)
              return [key, value]
            })
            console.log({ convertedData })
            this.appointments = new Map(convertedData)
          }
          else {
            this.appointments = new Map()
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  fetchMiniSessions(date) {
    const startDate = moment(date).startOf(cachePeriod).format("YYYY-MM-DD")
    const endDate = moment(date).endOf(cachePeriod).format("YYYY-MM-DD")
    const url = `/api/v1/mini_sessions?include=mini_session_slots&fields[mini_session][]=id&fields[mini_session][]=brand_name&fields[mini_session][]=title&fields[mini_session][]=starts_at&fields[mini_session][]=ends_at&fields[mini_session][]=all_day&fields[mini_session][]=formatted_time_range&fields[mini_session][]=mini_session_slots&fields[mini_session_slots][]=id&fields[mini_session_slots][]=formatted_slot_name&fields[mini_session_slots][]=starts_at&fields[mini_session_slots][]=ends_at`
    const params = {
      eventDateStart: startDate,
      eventDateEnd: endDate,
      apiKey: this.apiKey,
      per: 10000,
    }

    if(this.staffId) {
      params.staffId = this.staffId
    }

    if(this.brandId) {
      params.brandId = this.brandId
    }

    return getAlways(url, { params })
      .then(response => {
        if(response) {
          const data = asNormalizedArrays(response.data)
          if(data.miniSessionSlot) {
            const convertedSlotData = data.miniSessionSlot.map(record => {
              const key = ["miniSessionSlot", record.attributes.id].join("|")
              const value = this._prepareMiniSessionSlot(record)
              return [key, value]
            })
            this.miniSessionSlots = new Map(convertedSlotData)
          }
          else {
            this.miniSessionSlots = new Map()
          }
          if(data.miniSession) {
            const convertedMiniSessionData = data.miniSession.map(record => {
              const key = ["miniSession", record.attributes.id].join("|")
              const value = this._prepareMiniSession(record)
              return [key, value]
            })
            this.miniSessions = new Map(convertedMiniSessionData)
          }
          else {
            this.miniSessions = new Map()
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  fetchFranchiseBlockoutDateRanges(date) {
    const url = `/api/v1/franchise_blockout_date_ranges`
    const params = {
      apiKey: this.apiKey,
      per: 10000,
    }

    return getAlways(url, { params })
      .then(response => {
        if(response) {
          const data = asNormalizedArrays(response.data)
          if(data.franchiseBlockoutDateRange) {
            const convertedData = data.franchiseBlockoutDateRange.map(record => {
              const key = record.id
              const value = this._prepareFranchiseBlockoutDateRange(record)
              return [key, value]
            })

            this.franchiseBlockoutDateRanges = new Map(convertedData)
          }
          if(response.data.meta.availability) {
            this.franchiseDailyAvailability = new Map(Object.entries(response.data.meta.availability))
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  fetchStaffBlockoutDateRanges(date) {
    const url = `/api/v1/staff_blockout_date_ranges`
    let params = {
      apiKey: this.apiKey,
      per: 10000
    }

    if(this.staffId) {
      params.staffId = this.staffId
    }

    return getAlways(url, { params })
      .then(response => {
        if(response) {
          const data = asNormalizedArrays(response.data)
          if(data.staffBlockoutDateRange) {
            const convertedData = data.staffBlockoutDateRange.map(record => {
              const key = record.id
              const value = this._prepareStaffBlockoutDateRange(record)
              return [key, value]
            })
            this.staffBlockoutDateRanges = new Map(convertedData)
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  fetchStaff() {
    const url = `/api/v1/users`
    let params = {
      apiKey: this.apiKey,
      role: 'staff',
      per: 1000
    }

    if(this.staffId) {
      params.userId = this.staffId
    }

    return getAlways(url, { params })
      .then(response => {
        if(response) {
          const data = asNormalizedMaps(response.data)
          if(data && data.user) {
            this.staff = data.user
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  bookedSlotsForMiniSession(miniSessionId) {
    const miniSession = this.miniSessions.get(`miniSession|${miniSessionId}`)
    const slots = miniSession.relationships.miniSessionSlots.data
      .map(({ id, type }) => this.miniSessionSlots.get(`miniSessionSlot|${id}`))
      .filter(slot => slot)
      .filter(slot => slot.attributes.booked)

    return slots
  }

  _prepareEventBooking(record) {
    const { attributes } = record

    return {
      type: "event-booking",
      title: attributes.title,
      status: attributes.status,
      start: parseTime(attributes.startsAt),
      end: attributes.endsAt ? parseTime(attributes.endsAt) : null,
      allDay: false,
      attributes
    }
  }

  _prepareGroupedEventBooking(records) {
    if(records.length === 0) {
      return {}
    }
    else if(records.length === 1) {
      return {
        type: "event-booking",
        title: records[0].attributes.title,
        status: records[0].attributes.status,
        start: parseTime(records[0].attributes.startsAt),
        end: records[0].attributes.endsAt ? parseTime(records[0].attributes.endsAt) : null,
        allDay: false,
        attributes: records[0].attributes
      }
    }
    else {
      const startsAt = records
        .map(record => record.attributes.startsAt)
        .reduce((a, b) => a < b ? a : b )

      const endsAt = records
        .map(record => record.attributes.endsAt)
        .reduce((a, b) => a > b ? a : b )

      return {
        type: "grouped-event-booking",
        title: records[0].attributes.title,
        status: records[0].attributes.status,
        start: parseTime(startsAt),
        end: endsAt ? parseTime(endsAt) : null,
        allDay: false,
        attributes: records.map(record => record.attributes)
      }
    }
  }

  _prepareMiniSession(record) {
    const { attributes, relationships } = record

    return {
      type: "mini-session",
      title: attributes.title,
      start: parseTime(attributes.startsAt),
      end: parseTime(attributes.endsAt),
      allDay: false,
      attributes,
      relationships
    }
  }

  _prepareMiniSessionSlot(record) {
    const { attributes } = record

    return {
      type: "mini-session-slot",
      title: attributes.formattedSlotName,
      start: parseTime(attributes.startsAt),
      end: parseTime(attributes.endsAt),
      attributes
    }
  }

  _prepareLeadBooking(record) {
    const { attributes } = record

    return {
      type: "lead-booking",
      title: attributes.title,
      status: attributes.leadType,
      start: attributes.startsAt ? parseTime(attributes.startsAt) : null,
      end: attributes.endsAt ? parseTime(attributes.endsAt) : null,
      allDay: false,
      attributes
    }
  }

  _prepareSyncEvent(record) {
    const { attributes } = record

    return {
      type: "synced-event",
      summary: attributes.summary,
      title: attributes.description,
      start: parseTime(attributes.startsAt),
      end: attributes.endsAt ? parseTime(attributes.endsAt) : null,
      allDay: attributes.allDay,
      attributes
    }
  }

  _prepareAppointment(record) {
    const { attributes } = record

    return {
      type: "appointment",
      title: attributes.title,
      status: "",
      start: parseTime(attributes.startsAt),
      end: parseTime(attributes.endsAt),
      allDay: false,
      attributes
    }
  }

  _prepareFranchiseBlockoutDateRange(record) {
    const { attributes } = record

    const title = attributes.reasonWithPackages ? `Unavailable: ${attributes.reasonWithPackages}` : 'Company Off'

    return {
      type: "franchise-blockout-date-range",
      start: parseTime(attributes.startsAt),
      end: parseTime(attributes.endsAt),
      allDay: true,
      title,
      attributes
    }
  }

  _prepareFranchiseDayOff(date) {
    return {
      type: "franchise-blockout-date-range",
      title: `Unavailable ${moment(date).format('dddd')}s`,
      start: date,
      end: date,
      allDay: true
    }
  }

  _prepareStaffBlockoutDateRange(record) {
    const { attributes } = record

    const title = attributes.reason ?
      `${attributes.userFullName} Unavailable: ${attributes.reason}`
      : 
      `${attributes.userFullName} Unavailable`

    return {
      type: "staff-blockout-date-range",
      start: parseTime(attributes.startsAt),
      end: parseTime(attributes.endsAt),
      allDay: true,
      title,
      attributes
    }
  }

  _prepareStaffDayOff(date, staff, message) {
    const { attributes } = staff

    return {
      type: "staff-day-unavailable",
      title: `${attributes.fullName}: ${message}`,
      start: date,
      end: date,
      allDay: true,
      attributes
    }
  }

}

export function createPersistedStore(args) {
  const hydrate = create({})
  const store = new FranchiseCalendarStore(args)
  hydrate('FranchiseCalendarStore', store)
  return store
}

export default FranchiseCalendarStore
