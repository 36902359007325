import React, { useState, useContext } from 'react'
import FaIcon from '../../../../helpers/fa_icon'
import PlayerContext from './PlayerContext'
import extractSpotifyEmbedUrl from '../util/extractSpotifyEmbedUrl'

const SpotifyPreview = ({ spotifyUrl }) => {
  const { setActiveSpotifyUrl } = useContext(PlayerContext)

  if (!spotifyUrl) return null

  // If we're able to extract the embedded URL then we open the spotify player
  // inline.  If we can't, just let the user go wherever the URL is to avoid
  // errors
  const handleClick = (e) => {
    const embeddedUrl = extractSpotifyEmbedUrl(spotifyUrl)
    if(embeddedUrl) {
      e.preventDefault()
      setActiveSpotifyUrl(embeddedUrl)
    }
  }

  return (
    <span className="me-2">
      <a href={spotifyUrl} onClick={handleClick} target="_blank">
        <FaIcon icon="spotify" /> { " " } Spotify
      </a>
    </span>
  )
}


export default SpotifyPreview
