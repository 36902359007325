import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class SpecialDatesSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store } = this.props
    const {
      hideStatusSelector,
      enableBlockoutDates, showFranchiseBlockoutDates,
      enableStaffOff, showStaffBlockoutDates,
      enableSynced, showSynced,
      enableAppointments, showAppointments,
    } = store

    if(hideStatusSelector) {
      return null
    }

    if(!enableBlockoutDates && !enableStaffOff && !enableSynced && !enableAppointments) {
      return null
    }

    return (
      <div className="rbc-btn-group">
        { enableAppointments &&
          <button
            type="button"
            className={showAppointments ? 'rbc-active' : null}
            onClick={() => (store.toggleShowAppointments()) }
          >
            Appts
            { showAppointments && <div className="label-box appointments" /> }
          </button>
        }
        { enableBlockoutDates &&
          <button
            type="button"
            className={showFranchiseBlockoutDates ? 'rbc-active' : null}
            onClick={() => (store.toggleFranchiseBlockoutDates())}
          >
            Blockout Dates
            { showFranchiseBlockoutDates && <div className="label-box franchise-blockout-dates" /> }
          </button>
        }
        { enableStaffOff &&
          <button
            type="button"
            className={showStaffBlockoutDates ? 'rbc-active' : null}
            onClick={() => (store.toggleShowStaffBlockoutDates())}
          >
            { store.staffId ? "Time Off" : "Staff Off" }
            { showStaffBlockoutDates && <div className="label-box staff-blockout-dates" /> }
          </button>
        }
        { enableSynced &&
          <button
            type="button"
            className={showSynced ? 'rbc-active' : null}
            onClick={() => (store.toggleShowSynced()) }
          >
            Synced
            { showSynced && <div className="label-box synced" /> }
          </button>
        }

    </div>
    )
  }
}

