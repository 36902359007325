import React from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Button, FormGroup, FormControl } from 'react-bootstrap'
import {observer} from "mobx-react"
import ConditionalFieldEditor from './ConditionalFieldEditor'
import TimelineFieldEditor from './TimelineFieldEditor'
import ImageUploaderPreview from './ImageUploaderPreview'
import store from '../stores/store.js'
import fieldTypes from '../data/fieldTypes.json'

@observer
export default class FieldEditor extends React.Component {
  setProperties(properties = {}) {
    store.setFieldProperties(this.props.fieldGroupIndex, this.props.fieldIndex, properties)
  }

  render() {
    const { field, fieldGroupIndex, fieldIndex } = this.props
    const { conditionalField, conditionalMatcher, type } = field
    const meta = fieldTypes[type]

    if(this.props.field.editing) {
      let activeFieldTypes = Object.keys(fieldTypes)
      if(!store.supportsAdvancedMusicFeatures) {
        activeFieldTypes = activeFieldTypes.filter(fieldType => fieldType != "song" && fieldType != "song_list")
      }

      return (
        <Modal animation={false} show={true} onHide={() => this.setProperties({editing: false})} size="large">
          <Modal.Header closeButton>
            <Modal.Title>Edit Field</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup className="mb-2">
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={type}
                onChange={(e) => this.setProperties({type: e.target.value})}
                >
                { activeFieldTypes.map((fieldType) =>
                  <option key={fieldType} value={fieldType} disabled={fieldTypes[fieldType].separator}>
                    {fieldTypes[fieldType].name}
                  </option>
                )}
              </Form.Select>
            </FormGroup>
            { (!!meta.suggestionListsField && store.suggestionLists.length > 0) &&
            <div>
              <FormGroup className="mb-2">
                <Form.Label>Show Suggestions?</Form.Label>
                <Form.Select
                  value={field.suggestionListId || ''}
                  onChange={(e) => this.setProperties({suggestionListId: e.target.value})}
                  >
                  <option value={''}>Choose a suggestion list</option>
                  { store.suggestionLists.map(list =>
                    <option key={list.id} value={list.id}>
                      {list.title}
                    </option>
                  )}
                </Form.Select>
              </FormGroup>
              { field.suggestionListId &&
                <FormGroup className="mb-2">
                  <Form.Check
                    type="checkbox"
                    checked={!!field.allowCustomSongEntry}
                    onChange={e => this.setProperties({allowCustomSongEntry: e.target.checked})}
                    label="Allow entering custom songs not on the list?"
                    id="field-editor-allow-custom-song-entry"
                  />
                </FormGroup>
              }
            </div>
            }

            { meta.nameField &&
              <FormGroup className="mb-2">
                <Form.Label>Name</Form.Label>
                <FormControl
                  type="text"
                  autoFocus
                  value={field.name || ""}
                  onChange={(e) => this.setProperties({name: e.target.value})}
                />
              </FormGroup>
            }
            { meta.contentField &&
              <FormGroup className="mb-2">
                <Form.Label>Content</Form.Label>
                <FormControl
                  as="textarea"
                  type="text"
                  autoFocus
                  value={field.content || ""}
                  onChange={(e) => this.setProperties({content: e.target.value})}
                />
              </FormGroup>
            }
            { meta.labelField &&
              <FormGroup className="mb-2">
                <Form.Label>Label</Form.Label>
                <FormControl
                  type="text"
                  value={field.label || ""}
                  onChange={(e) => this.setProperties({label: e.target.value})}
                />
              </FormGroup>
            }
            { meta.optionsField &&
              <FormGroup className="mb-2">
                <Form.Label>Options</Form.Label>
                <FormControl
                  as="textarea"
                  value={field.values.join("\n")}
                  onChange={(e) => field.values = e.target.value.split(/\n/) }
                  onChange={(e) => {
                    let values = e.target.value.split(/\n/)
                    this.setProperties({values: values})
                  }}
                  style={{height: "8em"}}

                />
                <Form.Text muted>Enter one option per line</Form.Text>
              </FormGroup>
            }
            { meta.hintField &&
              <FormGroup className="mb-2">
                <Form.Label>Hint</Form.Label>
                <FormControl
                  type="text"
                  value={field.hint || ""}
                  onChange={(e) => this.setProperties({hint: e.target.value})}
                />
              </FormGroup>
            }
            { meta.placeholderField &&
              <FormGroup className="mb-2">
                <Form.Label>Placeholder</Form.Label>
                <FormControl
                  type="text"
                  value={field.placeholder || ""}
                  onChange={(e) => this.setProperties({placeholder: e.target.value})}
                />
              </FormGroup>
            }
            { meta.secondPlaceholderField &&
              <FormGroup className="mb-2">
                <Form.Label>Second Field Placeholder</Form.Label>
                <FormControl
                  type="text"
                  value={field.placeholder2 || ""}
                  onChange={(e) => this.setProperties({placeholder2: e.target.value})}
                />
              </FormGroup>
            }
            { meta.thirdPlaceholderField &&
              <FormGroup className="mb-2">
                <Form.Label>Third Field Placeholder</Form.Label>
                <FormControl
                  type="text"
                  value={field.placeholder3 || ""}
                  onChange={(e) => this.setProperties({placeholder3: e.target.value})}
                />
              </FormGroup>
            }
            { meta.fourthPlaceholderField &&
              <FormGroup className="mb-2">
                <Form.Label>Fourth Field Placeholder</Form.Label>
                <FormControl
                  type="text"
                  value={field.placeholder4 || ""}
                  onChange={(e) => this.setProperties({placeholder4: e.target.value})}
                />
              </FormGroup>
            }
            { meta.allowMultipleEntries &&
              <FormGroup className="mb-2">
                <Form.Check
                  type="checkbox"
                  checked={!!field.allowMultipleEntries}
                  onChange={e => this.setProperties({allowMultipleEntries: e.target.checked})}
                  label="Repeat this question multiple times?"
                  id="field-editor-allow-multiple-entries"
                />
              </FormGroup>
            }
            { meta.allowMultipleEntries && field.allowMultipleEntries && 
              <FormGroup className="mb-2">
                <Form.Label>Starting Number of Entries</Form.Label>
                <FormControl
                  type="number"
                  value={field.startingEntries || ''}
                  onChange={(e) => this.setProperties({startingEntries: e.target.value})}
                />
              </FormGroup>
            }
            { meta.allowMultipleEntries && field.allowMultipleEntries && 
              <FormGroup className="mb-2">
                <Form.Label>Maximum Number of Entries</Form.Label>
                <FormControl
                  type="number"
                  value={field.maximumEntries || ''}
                  onChange={(e) => this.setProperties({maximumEntries: parseInt(e.target.value)})}
                />
              </FormGroup>
            }
            { meta.allowRequired && 
              <FormGroup className="mb-2">
                <Form.Check
                  type="checkbox"
                  checked={!!field.required}
                  onChange={e => this.setProperties({required: e.target.checked})}
                  label="Make this a required field?"
                  id="field-editor-required"
                />
              </FormGroup>
            }
            <FormGroup className="mb-2">
              <Form.Check
                type="checkbox"
                checked={!!field.conditional}
                onChange={e => this.setProperties({conditional: e.target.checked})}
                label="Make this a conditional field?"
                id="field-editor-conditional"
              />
              <Form.Text muted>Conditional fields are only shown if certain conditions are met</Form.Text>
            </FormGroup>
            { field.conditional &&
              <ConditionalFieldEditor
                field={field}
                fieldGroupIndex={fieldGroupIndex}
                fieldIndex={fieldIndex}
              />
            }
            <FormGroup className="mb-2">
              <Form.Check
                type="checkbox"
                checked={!!field.timelineEnabled}
                onChange={e => this.setProperties({timelineEnabled: e.target.checked})}
                label="Enable timeline features for this field?"
                id="field-editor-timeline-enabled"
              />
              <Form.Text muted>Allow the customer to set a time or length of time for this field</Form.Text>
            </FormGroup>
            { field.timelineEnabled &&
              <TimelineFieldEditor
                field={field}
                fieldGroupIndex={fieldGroupIndex}
                fieldIndex={fieldIndex}
              />
            }
            { meta.imageField &&
              <FormGroup className="mb-2">
                <div style={{marginBottom: 10}}>
                  <Form.Label>Image</Form.Label>
                  <ImageUploaderPreview
                    apiKey={store.apiKey}
                    value={field.imageUrl || ''}
                    height={field.imageHeight}
                    width={field.imageWidth}
                    imageLink={field.imageLink}
                    onChange={file => this.setProperties({ imageUrl: file.url})}
                  />
                </div>

                <div style={{marginBottom: 10}}>
                  <Form.Label>Link URL</Form.Label>
                  <FormControl
                    type="text"
                    value={field.imageLink || ''}
                    onChange={e => this.setProperties({imageLink: e.target.value})}
                  />
                  <Form.Text muted>Optional URL</Form.Text>
                </div>

                <div className="row" style={{marginBottom: 10}}>
                  <div className="col-6">
                    <Form.Label>Height</Form.Label>
                    <FormControl
                      type="text"
                      value={field.imageHeight || ''}
                      onChange={e => this.setProperties({imageHeight: e.target.value})}
                    />
                    <Form.Text muted>Leave blank to automatically calculate</Form.Text>
                  </div>

                  <div className="col-6">
                    <Form.Label>Width</Form.Label>
                    <FormControl
                      type="text"
                      value={field.imageWidth || ''}
                      onChange={e => this.setProperties({imageWidth: e.target.value})}
                    />
                    <Form.Text muted>Leave blank to automatically calculate</Form.Text>
                  </div>
                </div>
              </FormGroup>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setProperties({editing: false})} className="w-100 btn-lg btn-primary">Save and Close</Button>
          </Modal.Footer>
        </Modal>
      )
    } else {
      return (
        <i className="fa fa-pencil" title="Edit Field" style={{cursor: 'pointer'}} onClick={() => this.setProperties({editing: true})}/>
      )
    }
  }
}

React.propTypes = {
  field: PropTypes.object.required,
  fieldIndex: PropTypes.number.required,
  fieldGroupIndex: PropTypes.number.required
}
