import React, { useState } from 'react'
import SearchBar from './SearchBar'
import SongList from './SongList'
import { Form, Button, Row, Col, FormGroup, FormControl, Accordion } from 'react-bootstrap'
import TextField from '../../ui/TextField'

const CustomSong = ({ currentSong, onSelect, editExtendedAttributes }) => {
  const [ song, setSong ] = useState({
    id: new Date().getTime(),
    name: '',
    artistName: '',
    albumName: '',
    publicNotes: '',
    spotifyUrl: '',
    appleMusicUrl: '',
    youtubeUrl: '',
    soundCloudUrl: '',
    provider: 'custom',
    ...currentSong
  })

  function handleAddSong(e) {
    e.preventDefault()
    onSelect(song)
  }

  return (
    <>
      <div className="mb-3">
        <h5>Song Details</h5>
        <Row>
          <Col sm={4}>
            <FormGroup>
              <FormControl
                name="songName"
                placeholder="Song Name"
                value={song.name}
                onChange={e => setSong({ ...song, name: e.target.value })}
                autoFocus
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormControl
                name="songArtist"
                placeholder="Artist"
                value={song.artistName}
                onChange={e => setSong({ ...song, artistName: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <FormControl
                name="songAlbum"
                placeholder="Album"
                value={song.albumName}
                onChange={e => setSong({ ...song, albumName: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      { editExtendedAttributes ? (
        <div className="mb-3">
          <h5>Additional Song Information</h5>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Song Previews</Accordion.Header>
              <Accordion.Body>
                <p>Optionally add preview links for this song</p>

                <FormGroup className="mb-2">
                  <FormControl
                    name="spotifyUrl"
                    placeholder="Spotify URL"
                    value={song.spotifyUrl}
                    onChange={e => setSong({ ...song, spotifyUrl: e.target.value })}
                    type="url"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <FormControl
                    name="appleMusicUrl"
                    placeholder="Apple Music URL"
                    value={song.appleMusicUrl}
                    onChange={e => setSong({ ...song, appleMusicUrl: e.target.value })}
                    type="url"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <FormControl
                    name="youtubeUrl"
                    placeholder="YouTube URL"
                    value={song.youtubeUrl}
                    onChange={e => setSong({ ...song, youtubeUrl: e.target.value })}
                    type="url"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <FormControl
                    name="soundCloudUrl"
                    placeholder="SoundCloud URL"
                    value={song.soundCloudUrl}
                    onChange={e => setSong({ ...song, soundCloudUrl: e.target.value })}
                    type="url"
                  />
                </FormGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Notes</Accordion.Header>
              <Accordion.Body>
                <FormGroup className="mb-2">
                  <FormControl
                    name="publicNotes"
                    placeholder="Public notes to display with this song"
                    as="textarea"
                    className="form-control"
                    rows={4}
                    type="text"
                    value={song.publicNotes}
                    onChange={e => setSong({ ...song, publicNotes: e.target.value })}
                  />
                </FormGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : (
        <div>
          <FormGroup className="mb-2">
            <FormControl
              name="selectionNotes"
              placeholder="Optionally include any additional notes about this selection"
              as="textarea"
              className="form-control"
              rows={4}
              type="text"
              value={song.selectionNotes}
              onChange={e => setSong({ ...song, selectionNotes: e.target.value })}
            />
          </FormGroup>
        </div>
      )}
      <div className="text-center">
        <Button
          variant="primary btn-lg w-100"
          type="submit"
          onClick={handleAddSong}
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default CustomSong
