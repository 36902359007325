import React, { useCallback, useState } from 'react'
import { Form, FormGroup, FormControl } from 'react-bootstrap'
import FaIcon from '../../../helpers/fa_icon'
import MusicBrowser from '../MusicBrowser'
import SongProviderLabel from '../SongProviderLabel'
import SpotifyPreview from './components/SpotifyPreview'
import AppleMusicPreview from './components/AppleMusicPreview'
import YoutubePreview from './components/YoutubePreview'
import SoundCloudPreview from './components/SoundCloudPreview'
import FloatingPlayer from './components/FloatingPlayer'
import './style.scss'

const SongPreview = (props) => {
  const { id, name, artistName, albumName, publicNotes, previewUrl, albumImageUrl, provider, suggestionListId, durationSeconds, onAdd, onUpdate, onRemove, selectionNotes: initialSelectionNotes, showConfirmAdd: initialShowConfirmAdd, musicBrowserProps, editExtendedAttributes, spotifyUrl, youtubeUrl, appleMusicUrl, soundCloudUrl, allowCustomSongEntry } = props
  const [ showConfirmAdd, setShowConfirmAdd ] = useState(initialShowConfirmAdd)
  const [ selectionNotes, setSelectionNotes ] = useState(initialSelectionNotes || '')

  const handleAddSong = useCallback((e) => {
    e.preventDefault()
    onAdd({ id, name, artistName, albumName, previewUrl, albumImageUrl, provider, suggestionListId, durationSeconds, selectionNotes, publicNotes, spotifyUrl, youtubeUrl, appleMusicUrl, soundCloudUrl})
  }, [id, name, artistName, albumName, previewUrl, albumImageUrl, provider, suggestionListId, durationSeconds, onAdd, selectionNotes, publicNotes, spotifyUrl, youtubeUrl, appleMusicUrl, soundCloudUrl])

  const handleConfirmAdd = useCallback((e) => {
    e.preventDefault()
    setShowConfirmAdd(true)
  }, [])

  const handleCancelAdd = useCallback((e) => {
    e.preventDefault()
    setShowConfirmAdd(false)
  }, [])


  if(!id) {
    return null
  }

  return (
    <div className="cc-song-preview">
      <div className="song-preview-row">
        { (onAdd && !showConfirmAdd) &&
          <a href="#"
            className="btn btn-secondary"
            onClick={handleConfirmAdd}
          >
            <FaIcon icon="plus" title="Add" />
            { " " }
            Choose
          </a>
        }
        { onUpdate &&
          <MusicBrowser
            {...musicBrowserProps}
            currentSong={props}
            suggestionListId={suggestionListId}
            allowCustomSongEntry={allowCustomSongEntry}
            onChange={onUpdate}
            onRemove={onRemove}
            editExtendedAttributes={editExtendedAttributes}
            label={<FaIcon icon="pencil" />}
          />
        }
        <div className="album-cover">
          { albumImageUrl && <img src={albumImageUrl} /> }
        </div>
        <div className="track-info">
          <div className="track-title">{ name }</div>
          { !!artistName && <div className="artist-name">Artist: { artistName }</div> }
          { !!albumName && <div className="album-name">Album: { albumName }</div> }
          <SongProviderLabel provider={provider} />
          { !!initialSelectionNotes && <div className="song-notes">Notes: { initialSelectionNotes }</div> }
        </div>
        { (spotifyUrl || youtubeUrl || appleMusicUrl || soundCloudUrl) &&
          <div className="preview-links">
            <div className="font-style-italic">
              Preview in:
            </div>

            <div>
              <SpotifyPreview spotifyUrl={spotifyUrl} />
              <AppleMusicPreview appleMusicUrl={appleMusicUrl} />
              <YoutubePreview youtubeUrl={youtubeUrl} />
              <SoundCloudPreview soundCloudUrl={soundCloudUrl} />
            </div>
          </div>
        }
      </div>
      { publicNotes &&
        <div className="song-notes">
          { publicNotes }
        </div>
      }
      { showConfirmAdd && (
        <div className="song-preview-row">
          <form className="song-notes-form">
            <FormGroup>
              <FormControl
                placeholder="Additional Notes"
                as="textarea"
                className="song-notes-textarea"
                type="text"
                value={selectionNotes}
                onChange={e => setSelectionNotes(e.target.value)}
              />
            </FormGroup>
            <div className="song-notes-buttons mt-2">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleAddSong}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancelAdd}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export { FloatingPlayer, SongPreview }
export default SongPreview
