import React from 'react'
import { observer } from "mobx-react"
import store from '../stores/store.js'
import PlaylistImporter from '../../ui/PlaylistImporter'
import FaIcon from '../../../helpers/fa_icon'

const SongPlaylistImporter = observer(({ field }) => {
  function handlePlaylistImport(playlist) {
    const { playlistName, playlistDescription, playlistArtworkUrl, playlistUrl, songs, playlistProvider } = playlist
    store.clearAllInstances(field.uuid)
    
    // Set playlist info
    store.setValueFor(field.uuid, "metaPlaylistMode", 0, "dynamic")
    store.setValueFor(field.uuid, "metaPlaylistName", 0, playlistName)
    store.setValueFor(field.uuid, "metaPlaylistDescription", 0, playlistDescription)
    store.setValueFor(field.uuid, "metaPlaylistArtworkUrl", 0, playlistArtworkUrl)
    store.setValueFor(field.uuid, "metaPlaylistUrl", 0, playlistUrl)
    store.setValueFor(field.uuid, "metaPlaylistProvider", 0, playlistProvider)

    // Set song info
    songs.forEach((song, index) => {
      store.setValueFor(field.uuid, "songId", index, song.attributes.id)
      store.setValueFor(field.uuid, "songName", index, song.attributes.name)
      store.setValueFor(field.uuid, "songArtistName", index, song.attributes.artistName)
      store.setValueFor(field.uuid, "songAlbumName", index, song.attributes.albumName)
      store.setValueFor(field.uuid, "songPreviewUrl", index, song.attributes.previewUrl)
      store.setValueFor(field.uuid, "songAlbumUrl", index, song.attributes.albumImageUrl)
      store.setValueFor(field.uuid, "songDurationSeconds", index, song.attributes.durationSeconds)
      store.setValueFor(field.uuid, "songProvider", index, song.attributes.provider)
      store.setValueFor(field.uuid, "suggestionListId", index, song.attributes.suggestionListId)
    })

    if(store.allowSave) {
      store.persistFormContent(null, true)
    }
  }

  const playlistMode = store.valueFor(field.uuid, "metaPlaylistMode", 0)
  const playlistUrl = store.valueFor(field.uuid, "metaPlaylistUrl", 0)
  const playlistName = store.valueFor(field.uuid, "metaPlaylistName", 0)
  const playlistDescription = store.valueFor(field.uuid, "metaPlaylistDescription", 0)
  const playlistArtworkUrl = store.valueFor(field.uuid, "metaPlaylistArtworkUrl", 0)
  const playlistProvider = store.valueFor(field.uuid, "metaPlaylistProvider", 0)
  const currentPlaylist = playlistUrl ? { playlistUrl, playlistDescription, playlistArtworkUrl, playlistName, playlistProvider } : null
  const labelText = currentPlaylist ? "Manage Playlist" : "Import Playlist"

  return (
    <PlaylistImporter
      currentPlaylist={currentPlaylist}
      onChange={handlePlaylistImport}
      label={<FaIcon icon="search-plus" text={labelText} />}
      disabled={!store.editable}
    />
  )
})

export default SongPlaylistImporter
