export default function extractAppleMusicEmbedUrl(url) {
  if(url.match(/https:\/\/music\.apple\.com\//)) {
    return url.replace('https://music.apple.com/', 'https://embed.music.apple.com/')
  }
  else if(url.match(/https:\/\/embed\.music\.apple\.com\//)) {
    return url
  }
  else {
    return null
  }
}
