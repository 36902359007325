import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, FormControl } from 'react-bootstrap'
import ColorPicker from '../ColorPicker'
import EventTypeSelector from '../EventTypeSelector'
import CopyableTextBlock from '../CopyableTextBlock'
import stringifyProps from './stringifyProps'

export default class AppointmentScheduler extends Component {
  state = {
    userId: this.props.userAppointmentCalendars[0].userId,
    showModal: false,
    style: "embed",
    maxWidth: "",
    iframe: false
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    userAppointmentCalendars: PropTypes.array.isRequired,
    apiKey: PropTypes.string.isRequired
  }

  showModal = () => {
    this.setState({showModal: true})
  }

  hideModal = () => {
    this.setState({showModal: false})
  }

  get html() {
    const { host, apiKey } = this.props
    const { style, userId, maxWidth, iframe} = this.state

    const baseProps = { apiKey, userId, iframe, host: `https://${host}` }
    let dataProps = {}
    const combinedProps = {...baseProps, ...dataProps}

    if(style === "basicLink") {
      let url = `https://${host}/schedule/${userId}`
      if(Object.keys(dataProps).length > 0) {
        url = `${url}?props=${encodeURIComponent(stringifyProps(dataProps))}`
      }
      return url
    }
    else if(style === "embed" && !iframe) {
      return `<script src="https://${host}/api/checkcherry_widgets" type="text/javascript" charset="utf-8"></script><div class="checkcherry__widget__appointment-scheduler" data-props='${stringifyProps(combinedProps)}'></div>`
    }
    else if(style === "embed" && iframe) {
      return `<iframe class="checkcherry-autoresize-frame" src="https://${host}/schedule/${userId}?iframe=true&props=${encodeURIComponent(stringifyProps(dataProps))}" style="margin: 0; padding: 0; border: none; max-width: ${maxWidth || "500px"}; width: 100%; height: 400px" scrolling="auto" allowtransparency="true"></iframe><script src="https://${host}/api/checkcherry_widgets/iframe" type="text/javascript" charset="utf-8"></script>`
    }
    else {
      return ""
    }
  }

  render() {
    const { type, userAppointmentCalendars } = this.props
    const { showModal, style, userId, maxWidth, iframe} = this.state

    return (
      <div>
        <div className="btn-group">
          <Button onClick={this.showModal} variant="primary">
            <i className="fa fa-code" />
            { " " }
            Get Embed Code
          </Button>
        </div>
        <Modal show={showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Availability Scheduler Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={7}>
                <h3>Customize</h3>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Style</Form.Label>
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "basicLink"}
                    onChange={() => this.setState({style: "basicLink"})}
                    label="Link Only"
                    id="contact-form-style-link"
                  />
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "embed"}
                    onChange={() => this.setState({style: "embed"})}
                    label="Embed on my site"
                    id="contact-form-style-embed"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Staff</Form.Label>
                  { userAppointmentCalendars.map(userAppointmentCalendar =>
                    <Form.Check
                      type="radio"
                      key={userAppointmentCalendar.userId}
                      name="userAppointmentCalendar"
                      checked={userAppointmentCalendar.userId === userId }
                      onChange={() => this.setState({userId: userAppointmentCalendar.userId})}
                      label={userAppointmentCalendar.fullName}
                      id={`user-appointment-calendar-${userAppointmentCalendar.id}`}
                    />
                  )}
                  <div>
                    Note: Only staff with the "Enhanced Scheduling Role" will show up here.  Visit the 
                    { " " }
                    <a href="/admin/users">user account page</a>
                    { " " }
                    to add this role to a user.
                  </div>
                </FormGroup>
                { style === "embed" &&
                <FormGroup className="mb-2">
                  <Form.Label>Enable Compatibility Mode?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="iframe"
                    checked={iframe === true}
                    onChange={() => this.setState({iframe: true})}
                    label="Yes"
                    id="user-appointment-calendar-iframe-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="iframe"
                    checked={iframe === false}
                    onChange={() => this.setState({iframe: false})}
                    label="No"
                    id="user-appointment-calendar-iframe-no"
                  />
                  <Form.Text muted>Compatibility mode embeds widget within an iFrame for greater compatibility with some website builders</Form.Text>
                </FormGroup>
                }
              </Col>
              <Col sm={5}>
                <h3>HTML</h3>

                <p>Copy and paste the HTML below into your website to add your contact form</p>

                <FormGroup>
                  <CopyableTextBlock value={this.html} />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
