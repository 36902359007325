import React, { useState, useEffect, useMemo } from 'react'
import { Nav, Button, Modal } from 'react-bootstrap'
import CurrentPlaylistDisplay from './CurrentPlaylistDisplay'
import RefreshButton from './RefreshButton'
import SpotifyPlaylistImporter from './SpotifyPlaylistImporter'
import AppleMusicPlaylistImporter from './AppleMusicPlaylistImporter'
import client from '../../../helpers/client'


const ALL_PROVIDERS = [
  { value: "current", label: "Current Playlist"},
  { value: "spotify", label: "Spotify" },
  { value: "apple", label: "Apple Music" },
]

function availableProvidersFor({ currentPlaylist }) {
  return ALL_PROVIDERS.filter(provider => {
    if (provider.value == "current") {
      return !!currentPlaylist
    } else {
      return true
    }
  })
}

const PlaylistImporter = ({ label, disabled, onChange, currentPlaylist }) => {
  const availableProviders = useMemo(() => availableProvidersFor({ currentPlaylist }), [currentPlaylist])

  const [ provider, setProvider ] = useState(availableProviders[0]?.value)
  const [ showPlaylistPicker, setShowPlaylistPicker ] = useState(false)

  useEffect(() => {
    // If the number of availableProviders changes, reset to the first one
    setProvider(availableProviders[0]?.value)
  }, [availableProviders.length])


  function handlePlaylistSelected(playlist) {
    onChange(playlist)
    setShowPlaylistPicker(false)
  }

  function handlePlaylistRefreshed(playlist) {
    onChange(playlist)
  }

  if(!availableProviders.length) return null

  return (
    <>
      <Button onClick={() => setShowPlaylistPicker(true)} disabled={disabled}>{ label }</Button>
      <RefreshButton onChange={handlePlaylistRefreshed} currentPlaylist={currentPlaylist} />
      <Modal show={showPlaylistPicker} onHide={() => setShowPlaylistPicker(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Playlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={styles.navWrapper}>
            <Nav variant="pills" activeKey={provider} onSelect={provider => setProvider(provider)}>
              { availableProviders.map(provider => (
                <Nav.Item key={provider.value}>
                  <Nav.Link eventKey={provider.value}>
                    {provider.label}
                  </Nav.Link>
                </Nav.Item>
              )) }
            </Nav>
          </div>
          { provider == "current" &&
            <CurrentPlaylistDisplay
              currentPlaylist={currentPlaylist}
              onSelect={handlePlaylistSelected}
            />
          }
          { provider == "spotify" &&
            <SpotifyPlaylistImporter onSelect={handlePlaylistSelected} />
          }
          { provider == "apple" &&
            <AppleMusicPlaylistImporter onSelect={handlePlaylistSelected} />
          }
        </Modal.Body>
      </Modal>
    </>

  )
}

const styles = {
  navWrapper: {
    marginBottom: 20
  }
}

export default PlaylistImporter
