import React from 'react'
import { ListGroup } from 'react-bootstrap'
import SearchResultAppointment from './SearchResultAppointment'

const SearchResultListAppointments = ({ appointments, searchValue }) => {
  if(appointments.data.length === 0) {
    return null
  }

  // const params = new URLSearchParams({
  //   "query[query]" : searchValue,
  //   "query[show_past]": "true",
  //   "query[show_future]": "true",
  //   "query[show_proposals]": "true",
  //   "query[show_confirmed_events]": "false",
  //   "query[show_sessions]": "true",
  // })
  // const moreResultsPath = `/events?${params.toString()}`

  return (
    <div className="mb-3">
      <h5>
        Appointments
        {/* <a href={moreResultsPath} className="text-dark"> */}
        {/*   Appointments &raquo; */}
        {/* </a> */}
      </h5>
      <ListGroup>
        {appointments.data.map(result => (
          <SearchResultAppointment key={result.id} result={result} />
        ))}
      </ListGroup>
      {/* <div className="text-end mt-2"> */}
      {/*   <a className="text-muted" href={moreResultsPath}>Search all appointments &raquo;</a> */}
      {/* </div> */}
    </div>
  )
}

export default SearchResultListAppointments
