import React from 'react'
import { observer, inject } from 'mobx-react'


const PrinterControl = ({ checklistStore, printFriendlyPath }) => {
  if(!printFriendlyPath) return null

  const params = {
    filter: checklistStore.filter,
    assigned_filter: checklistStore.assignedFilter,
    sort_by: checklistStore.sort,
  }

  const fullPath = `${printFriendlyPath}?${new URLSearchParams(params).toString()}`

  return (
    <div className="text-end mb-3">
      <a href={fullPath} target="_blank" rel="noopener noreferrer">
        <i className="fa fa-print"></i>
        { " " }
        Print Friendly View
      </a>
    </div>
  )
}

export default inject("checklistStore")(observer(PrinterControl))
