import React, { useState, useContext } from 'react'
import FaIcon from '../../../../helpers/fa_icon'
import PlayerContext from './PlayerContext'
import extractSoundCloudEmbedUrl from '../util/extractSoundCloudEmbedUrl'

const SoundCloudPreview = ({ soundCloudUrl }) => {
  const { setActiveSoundCloudUrl } = useContext(PlayerContext)

  if(!soundCloudUrl) return null

  // If we're able to extract the embedded URL then we open the spotify player
  // inline.  If we can't, just let the user go wherever the URL is to avoid
  // errors
  const handleClick = (e) => {
    const embeddedUrl = extractSoundCloudEmbedUrl(soundCloudUrl)
    if(embeddedUrl) {
      e.preventDefault()
      setActiveSoundCloudUrl(embeddedUrl)
    }
  }

  return (
    <span className="me-2">
      <a href={soundCloudUrl} onClick={handleClick} target="_blank">
        <FaIcon icon="soundcloud" /> { " " } SoundCloud
      </a>
    </span>
  )
}

export default SoundCloudPreview
