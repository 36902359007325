import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import TimeSlotContainer from './TimeSlotContainer'
import CartPreview from './CartPreview'
import { format } from 'date-fns'

@inject("store")
@observer
export default class TimeSlotSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store } = this.props
    const { slots } = store

    return (
      <div className="time-chooser-container">
        <div className="time-chooser-date-header">
          <div>Available times for { format(store.selectedDate, "MMMM do, yyyy") }</div>
        </div>
        <TimeSlotContainer slots={slots} />
      </div>
    )
  }
}
