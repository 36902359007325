import { action, observable, computed, autorun, reaction, toJS } from 'mobx'
import 'whatwg-fetch'
import fieldTypes from '../data/fieldTypes.json'
import elementsForField from '../util/elementsForField'
import mergeField from '../util/mergeField'
import evaluateAllConditionals from '../util/evaluateAllConditionals'


class ObservableStore {
  @observable activeFieldGroup = "tab-0"

  @observable loading = false
  @observable mode // one of build, edit, show, preview
  @observable disableEdit = false
  @observable unlockable = false
  @observable formTemplateId
  @observable eventId
  @observable formId
  @observable title = 'New Questionnaire'
  @observable fieldGroups = []
  @observable suggestionLists = []
  @observable values = new Map()
  @observable errors = new Map()
  @observable fieldGroupErrorCounts = new Map()
  @observable complete = false
  @observable printVersion = false
  @observable notification
  @observable _previousStringifiedData
  @observable _previousStringifiedTemplate

  @observable allCategories = false
  @observable eventCategoryIds = []
  @observable packageGroupIds = []
  @observable eventTypeIds = []
  @observable allMiniSessions = false
  @observable miniSessionIds = []
  @observable allAddOnCategories = false
  @observable addOnCategoryIds = []
  @observable addOnIds = []

  @observable selectedPackageIds = []
  @observable selectedAddOnIds = []
  @observable selectedBackdropIds = []

  @observable eventTypeSelectorSettings = null

  @observable loadableComponentCount = 0
  @observable completedLoadableComponentCount = 0

  @observable supportsAdvancedMusicFeatures = false

  @computed get buildable() {
    return this.mode == "build"
  }

  @computed get editable() {
    return this.mode == "edit" || this.mode == "preview"
  }

  @computed get showable() {
    return this.mode == "show"
  }

  @computed get previewable() {
    return this.mode == "preview"
  }

  @computed get allowSave() {
    if(this.mode === 'build') {
      return true
    }
    if(this.loading) {
      return false
    }
    return this.dataHasChanged || this.templateHasChanged
  }

  @computed get _stringifiedData() {
    return JSON.stringify(this.values.toJSON())
  }

  @computed get _stringifiedTemplate() {
    return JSON.stringify(this.fieldGroups.toJSON())
  }

  @computed get dataHasChanged() {
    return this._stringifiedData !== this._previousStringifiedData
  }

  @computed get templateHasChanged() {
    return this._stringifiedTemplate !== this._previousStringifiedTemplate
  }

  @action registerLoadableComponent() {
    this.loadableComponentCount = this.loadableComponentCount + 1
  }

  @action componentFinishedLoading() {
    this.completedLoadableComponentCount = this.completedLoadableComponentCount + 1
    if(this.completedLoadableComponentCount >= this.loadableComponentCount) {
      if(this.printVersion) {
        window.print()
      }
    }
  }

  @action removeInstance(uuid, instance) {
    let data = this.valuesForUuid(uuid.toString())
    const spliced = data.splice(instance, 1)
    if(instance === 0) {
      // If removing the first instance, we need to preserve any metadata that
      // may be present in the first instance by copying it to the next instance
      // before removing the first instance.  Any keys that need preserved should
      // start with "meta"
      const nextInstance = data[0]
      if(nextInstance) {
        spliced[0].forEach((value, key) => {
          if(key.startsWith("meta")) {
            nextInstance.set(key, value)
          }
        })
      }
    }
    console.log({ instance, spliced: toJS(spliced), data: toJS(data) })
    this.values.set(uuid.toString(), data)
  }

  @action clearAllInstances(uuid) {
    const data = []
    data.push(new Map())
    this.values.set(uuid.toString(), data)
  }

  instancesFor(uuid) {
    let data = this.valuesForUuid(uuid.toString())

    if(data.length) {
      return data.length
    }
    else {
      return 1
    }
  }

  fieldGroupFor(uuid) {
    return this.fieldGroups.map((fieldGroup) => fieldGroup.fields.slice())
      .reduce((acc, cur) => acc.concat(cur))
      .find((field) => field.uuid.toString() == uuid.toString())

  }

  fieldEditingIsLocked(field) {
    if(!field) return false

    if(field.type == "song_list") {
      const playlistMode = store.valueFor(field.uuid, "metaPlaylistMode", 0)
      return playlistMode == "dynamic"
    }

    return false
  }

  @action setNotification(message) {
    if(message) {
      window.scrollTo(0, 0)
      this.notification = message
      setTimeout(() => this.notification = null, 2500)
    }
  }

  @action incrementInstancesFor(uuid) {
    let field = this.fieldGroupFor(uuid.toString())
    let data = this.valuesForUuid(uuid.toString())
    data.push(new Map())
    this.values.set(uuid.toString(), data)
  }

  valueFor(uuid, element, instance = 0) {
    let data = this.valuesForUuid(uuid.toString())
    if(data.length <= instance || !data[instance]) {
      return ""
    }
    else {
      return data[instance].get(element) || ""
    }
  }

  validateField(field, instance) {
    const meta = fieldTypes[field.type]
    if(field.required && meta.allowRequired) {
      const elements = elementsForField(field)
      const blank = elements.find(element => !this.valueFor(field.uuid, element, instance))
      if(blank) {
        return "can't be blank"
      }
    }

    return null
  }

  shouldDisplayField(field) {
    const { conditional, conditions } = field

    if(!conditional) return true
    if(!conditions) return true
    if(!conditions.length) return true

    const {
      selectedEventCategoryIds,
      selectedPackageGroupIds,
      selectedPackageIds,
      selectedAddOnCategoryIds,
      selectedAddOnIds,
      selectedBackdropIds
    } = this

    return evaluateAllConditionals({
      field,
      selectedEventCategoryIds,
      selectedPackageGroupIds,
      selectedPackageIds,
      selectedAddOnCategoryIds,
      selectedAddOnIds,
      selectedBackdropIds
    })
  }

  errorFor(uuid, element, instance = 0) {
    const errorKey = `${uuid}:${instance}`
    return this.errors.get(errorKey)
  }

  valuesForUuid(uuid) {
    const field = this.fieldGroupFor(uuid.toString())
    const mergedField = mergeField(field)
    const startingNumberOfEntries = (mergedField.startingEntries && mergedField.startingEntries > 0) ? mergedField.startingEntries : 1
    return this.values.get(uuid.toString()) || new Array(parseInt(startingNumberOfEntries)).fill(null).map(() => new Map())
  }

  @action setValueFor(uuid, element, instance, value) {
    // console.log({ uuid, element, instance, value })
    let data = this.valuesForUuid(uuid.toString())
    if(!data.length) {
      data = [new Map()]
    }
    if(data.length <= instance) {
      this.incrementInstancesFor(uuid)
    }
    data[instance].set(element, value)
    this.values.set(uuid.toString(), data)

    if(this.errorFor(uuid, element, instance)) {
      this.validateForm()
    }
  }

  @action addField(fieldGroupIndex, position = "bottom") {
    const uuid = this.generateUuid()
    let newField = {
      uuid: uuid.toString(), type: 'text_field', name: '', editing: true, values: [], hint: "", content: ""
    }
    if(position == "top") {
      this.fieldGroups[fieldGroupIndex].fields.unshift(newField)
    }
    else {
      this.fieldGroups[fieldGroupIndex].fields.push(newField)
    }
  }

  @action setField(fieldGroupIndex, fieldIndex, field) {
    this.fieldGroups[fieldGroupIndex].fields[fieldIndex] = field
  }

  @action setFieldProperties(fieldGroupIndex, fieldIndex, properties = {}) {
    const field = this.fieldGroups[fieldGroupIndex].fields[fieldIndex]
    const mergedField = {...field, ...properties}
    this.setField(fieldGroupIndex, fieldIndex, mergedField)
  }

  @action removeField(fieldGroupIndex, fieldIndex) {
    this.fieldGroups[fieldGroupIndex].fields.splice(fieldIndex, 1)
  }

  @action duplicateField(fieldGroupIndex, fieldIndex) {
    const field = this.fieldGroups[fieldGroupIndex].fields[fieldIndex]
    const conditions = field.conditions ? [...field.conditions] : []
    const newField = {
      ...field,
      conditions,
      uuid: this.generateUuid()
    }

    const newFields = [
      ...this.fieldGroups[fieldGroupIndex].fields.slice(0, fieldIndex + 1),
      newField,
      ...this.fieldGroups[fieldGroupIndex].fields.slice(fieldIndex + 1)
    ]
    this.fieldGroups[fieldGroupIndex].fields = newFields
  }

  @action addFieldGroup(name) {
    const newFieldGroup = { name, editing: true, allowSort: true, fields: [] }
    this.fieldGroups.push(newFieldGroup)
  }

  @action duplicateFieldGroup(fieldGroupIndex) {
    const fieldGroup = this.fieldGroups[fieldGroupIndex]

    const name = `${fieldGroup.name} Copy`
    const fields = fieldGroup.fields.map(field => {
      return {...field, uuid: this.generateUuid()}
    })
    const newFieldGroup = { ...fieldGroup, name, fields }
    this.fieldGroups.splice(fieldGroupIndex + 1, 0, newFieldGroup)
  }

  @action removeFieldGroup(fieldGroupIndex) {
    this.fieldGroups.splice(fieldGroupIndex, 1)
    this.activeFieldGroup = `tab-0`
  }

  @action reorderField(fieldGroupIndex, sourceFieldIndex, destinationFieldIndex) {
    let fields = this.fieldGroups[fieldGroupIndex].fields
    fields.splice(destinationFieldIndex, 0, fields.splice(sourceFieldIndex, 1)[0]);
    this.fieldGroups[fieldGroupIndex].fields = fields
  }

  @action moveFieldUp(fieldGroupIndex, fieldIndex) {
    let fields = this.fieldGroups[fieldGroupIndex].fields
    fields.splice(fieldIndex - 1, 0, fields.splice(fieldIndex, 1)[0]);
    this.fieldGroups[fieldGroupIndex].fields = fields
  }

  @action moveFieldDown(fieldGroupIndex, fieldIndex) {
    let fields = this.fieldGroups[fieldGroupIndex].fields
    fields.splice(fieldIndex + 1, 0, fields.splice(fieldIndex, 1)[0]);
    this.fieldGroups[fieldGroupIndex].fields = fields
  }

  @action moveFieldGroupLeft(fieldGroupIndex) {
    let fieldGroups = this.fieldGroups
    fieldGroups.splice(fieldGroupIndex - 1, 0, fieldGroups.splice(fieldGroupIndex, 1)[0]);
    this.fieldGroups = fieldGroups
    store.activeFieldGroup = `tab-${fieldGroupIndex - 1}`
  }

  @action moveFieldGroupRight(fieldGroupIndex) {
    let fieldGroups = this.fieldGroups
    fieldGroups.splice(fieldGroupIndex + 1, 0, fieldGroups.splice(fieldGroupIndex, 1)[0]);
    this.fieldGroups = fieldGroups
    store.activeFieldGroup = `tab-${fieldGroupIndex + 1}`
  }

  @action setFormAsComplete() {
    let url = `/events/${this.eventId}/forms/${this.formId}/set_complete.json`

    let store = this
    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin'
    }).then((response) => {
      if(!response.ok) {
        let error = new Error(response.statusText)
        error.response = response
        throw error
      }
      return response
    })
    .then((response) => {
      store.mode = 'show'
      store.complete = true
    })
    .catch((error) => {
      alert(error.message)
    })
  }

  @action setFormAsIncomplete() {
    const url = `/events/${this.eventId}/forms/${this.formId}/set_incomplete.json`
    const store = this

    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin'
    }).then((response) => {
      if(!response.ok) {
        let error = new Error(response.statusText)
        error.response = response
        throw error
      }
      return response
    })
    .then((response) => {
      store.mode = 'edit'
      store.complete = false
    })
    .catch((error) => {
      alert(error.message)
    })
  }

  @action validateForm() {
    const errors = new Map()
    let totalErrorCount = 0

    this.fieldGroups.forEach((fieldGroup, fieldGroupIndex) => {
      let fieldGroupErrorCount = 0

      fieldGroup.fields.forEach(field => {
        const numberInstances = this.instancesFor(field.uuid)
        for(let i = 0; i < numberInstances; i++) {
          if(!this.shouldDisplayField(field)) {
            continue
          }

          const error = this.validateField(field, i)

          if(error) {
            const errorKey = `${field.uuid}:${i}`
            errors.set(errorKey, error)
            fieldGroupErrorCount++
            totalErrorCount++
          }
        }
      })

      this.fieldGroupErrorCounts.set(fieldGroupIndex, fieldGroupErrorCount)
    })

    this.errors = errors

    return totalErrorCount === 0
  }

  @action persistFormContent(callback, autosave = false) {
    if(!this.eventId) {
      return
    }

    if(this.templateHasChanged) {
      this.persistFormTemplate()
    }

    this.loading = true
    let body = {
      values: this.values.toJSON()
    }

    // Update existing form
    let url = `/events/${this.eventId}/forms/${this.formId}.json`
    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if(!response.ok) {
        let error = new Error(response.statusText)
        error.response = response
        throw error
      }
      return response
    })
    .then((response) => {
      this.loading = false
      if(!autosave) {
        this.setNotification("Your changes have been saved")
      }
      this._previousStringifiedData = this._stringifiedData
      if(callback) {
        callback()
      }
    })
    .catch((error) => {
      alert(error.message)
      this.loading = false
    })
  }

  @action persistFormTemplate(callback) {
    this.loading = true
    const formData = JSON.stringify({
      title: this.title,
      fieldGroups: this.fieldGroups
    })

    let self = this
    if(this.eventId && this.formId) {
      const updateFormStructureBody = { data: formData }

      // Update existing form
      const updateUrl = `/events/${this.eventId}/forms/${this.formId}/update_form_structure.json`
      fetch(updateUrl, {
        method: 'PUT',
        body: JSON.stringify(updateFormStructureBody),
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      }).then((response) => {
        if(!response.ok) {
          let error = new Error(response.statusText)
          error.response = response
          throw error
        }
        return response
      })
      .then((response) => {
        this.loading = false
        this.setNotification("Your changes have been saved")
        this._previousStringifiedTemplate = this._stringifiedTemplate
        if(callback) {
          callback();
        }
      })
      .catch((error) => {
        this.loading = false
        alert(error.message)
      })
    }
    else if(this.formTemplateId) {
      const editFormBody = {
        data: formData,
        all_categories: this.allCategories,
        event_type_ids: this.eventTypeIds,
        package_group_ids: this.packageGroupIds,
        event_category_ids: this.eventCategoryIds,
        all_mini_sessions: this.allMiniSessions,
        mini_session_ids: this.miniSessionIds,
        all_add_on_categories: this.allAddOnCategories,
        add_on_category_ids: this.addOnCategoryIds,
        add_on_ids: this.addOnIds
      }

      // Update existing form template
      fetch(`/admin/form_templates/${this.formTemplateId}.json`, {
        method: 'PUT',
        body: JSON.stringify(editFormBody),
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      }).then((response) => {
        if(!response.ok) {
          let error = new Error(response.statusText)
          error.response = response
          throw error
        }
        return response
      })
      .then((response) => {
        this.loading = false
        this.setNotification("Your changes have been saved")
        if(callback) {
          callback();
        }
      })
      .catch((error) => {
        this.loading = false
        alert(error.message)
      })
    }
    else {
      const createFormBody = {
        data: formData,
        all_categories: this.allCategories,
        event_type_ids: this.eventTypeIds,
        package_group_ids: this.packageGroupIds,
        event_category_ids: this.eventCategoryIds,
        all_mini_sessions: this.allMiniSessions,
        mini_session_ids: this.miniSessionIds,
        all_add_on_categories: this.allAddOnCategories,
        add_on_category_ids: this.addOnCategoryIds,
        add_on_ids: this.addOnIds
      }

      // Create new form template
      fetch('/admin/form_templates.json', {
        method: 'POST',
        body: JSON.stringify(createFormBody),
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      }).then((response) => {
        if(!response.ok) {
          let error = new Error(response.statusText)
          error.response = response
          throw error
        }
        return response
      })
      .then((response) => response.json() )
      .then((json) => {
        this.loading = false
        this.setNotification("Your changes have been saved")
        self.formTemplateId = json.id
        if(history && history.pushState) {
          history.pushState("Edit Questionnaire", "Edit Questionnaire", `/admin/form_templates/${json.id}/edit`)
        }
      })
      .catch((error) => {
        this.loading = false
        alert(error.message)
      })
    }
  }

  @action enterPreviewMode() {
    store.mode = 'preview'
  }

  @action exitPreviewMode() {
    store.mode = 'build'
  }

  generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}


const store = new ObservableStore()
export default store
