import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class Appointment extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      attributes: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        formattedTimeRange: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }

  render() {
    const { event, store } = this.props
    const { showBrands } = store
    const { id, title, formattedTimeRange, canceled, brandName, htmlLink } = event.attributes

    return (
      <a href={`/appointments/${id}`} className="calendar-event" data-calendar-id={id}>
        <div>{ formattedTimeRange }</div>
        { showBrands &&
          <div>[{ brandName }]</div>
        }
        <div style={{fontWeight: "bold"}}>{ title }</div>
        <div>
          Appointment
          <em>{ canceled ? " (Canceled)" : "" }</em>
        </div>
      </a>
    )
  }
}

