export default function extractSpotifyEmbedUrl(url) {
  if(url.match(/https:\/\/open\.spotify\.com\/track\/.*/)) {
    return url.replace('https://open.spotify.com/track/', 'https://open.spotify.com/embed/track/')
  }
  else if(url.match(/https:\/\/open\.spotify\.com\/album\/.*/)) {
    return url.replace('https://open.spotify.com/album/', 'https://open.spotify.com/embed/album/')
  }
  else if(url.match(/https:\/\/open\.spotify\.com\/playlist\/.*/)) {
    return url.replace('https://open.spotify.com/playlist/', 'https://open.spotify.com/embed/playlist/')
  }
  else if(url.match(/https:\/\/open\.spotify\.com\/artist\/.*/)) {
    return url.replace('https://open.spotify.com/artist/', 'https://open.spotify.com/embed/artist/')
  }
  else {
    return null
  }
}
