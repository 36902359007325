import React from 'react'
import PropTypes from 'prop-types'
import { Provider, observer } from 'mobx-react'
import { createPersistedStore } from './stores/ChecklistStore'
import ChecklistView from './components/ChecklistView'
import NewChecklistItem from './components/NewChecklistItem'
import PrinterControl from './components/PrinterControl'
import BulkEditorToolbar from './components/BulkEditorToolbar'
import ReloadButton from './components/ReloadButton'
import Loader from './components/Loader'
import ErrorHandler from './components/ErrorHandler'
import ToolbarCompact from './components/ToolbarCompact'
import ToolbarExpanded from './components/ToolbarExpanded'
import './css/index.scss'

@observer
export default class Checklist extends React.Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    checklistId: PropTypes.number,
    dateFormat: PropTypes.string,
    showCheckables: PropTypes.bool,
    availableFilters: PropTypes.array.isRequired,
    defaultFilter: PropTypes.string.isRequired,
    availableAssignedFilters: PropTypes.array.isRequired,
    defaultAssignedFilter: PropTypes.string.isRequired,
    editChecklist: PropTypes.bool.isRequired,
    showProposalToggle: PropTypes.bool,
    layout: PropTypes.oneOf(['compact', 'expanded']),
    persistenceKey: PropTypes.string,
    printFriendlyPath: PropTypes.string,
  }

  static defaultProps = {
    showCheckables: false,
    editChecklist: false,
    showProposalToggle: false,
    layout: 'expanded',
  }

  checklistStore = createPersistedStore(this.props.persistenceKey, {
    apiKey: this.props.apiKey,
    checklistId: this.props.checklistId,
    availableFilters: this.props.availableFilters,
    defaultFilter: this.props.defaultFilter,
    availableAssignedFilters: this.props.availableAssignedFilters,
    defaultAssignedFilter: this.props.defaultAssignedFilter,
    availableSortOptions: this.props.availableSortOptions,
    defaultSort: this.props.defaultSort,
    editChecklist: this.props.editChecklist,
    showProposals: !this.props.showProposalToggle,
    perPage: this.props.perPage,
  })

  componentDidMount() {
    this.checklistStore.setup()
  }

  render() {
    const { layout, dateFormat, taskableOptions, showCheckables, showProposalToggle, printFriendlyPath } = this.props
    const { ready, loading } = this.checklistStore

    if(!ready) {
      return null
    }

    return (
      <Provider
        checklistStore={this.checklistStore}
        dateFormat={dateFormat}
      >
        <div className={`checklist-component`}>
          <PrinterControl 
            printFriendlyPath={printFriendlyPath}
          />

          <ErrorHandler>
            { layout === "compact" ?
              <ToolbarCompact {...this.props} />
              :
              <ToolbarExpanded {...this.props} />
            }

            <div className="spacer-top">
              <Loader loading={loading}>
                { layout === "expanded" && <BulkEditorToolbar /> }
                <ChecklistView showCheckables={showCheckables} />
              </Loader>
            </div>
          </ErrorHandler>
        </div>
      </Provider>
    )
  }
}


