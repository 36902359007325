import React from 'react';
import { ListGroup } from 'react-bootstrap';
import BrandBadge from './BrandBadge';

const SearchResultAppointment = ({ result, ...props }) => {
  const { brandId, title, datetimeDescription, previewImage, url } = result.attributes

  return (
    <ListGroup.Item action {...props} href={url} className="global-search-result-item" active={false}>
      <div className="global-search-preview-image-container text-dark">
        <img src={previewImage} alt={title} className="img-fluid" />
      </div>
      <div className="global-search-preview-details-container">
        <div>
          <span className="text-dark fw-medium"> { title } </span>
          <BrandBadge brandId={brandId} />
        </div>
        { datetimeDescription && <div className="text-muted">{ datetimeDescription }</div>
        }
        {/* <div className={`text-dark status ${status}`}>{ formattedStatus }</div> */}
      </div>
    </ListGroup.Item>
  )
}

export default SearchResultAppointment
