import React, { useState, useContext } from 'react'
import FaIcon from '../../../../helpers/fa_icon'
import PlayerContext from './PlayerContext'
import extractYoutubeEmbedUrl from '../util/extractYoutubeEmbedUrl'

const YoutubePreview = ({ youtubeUrl }) => {
  const { setActiveYoutubeUrl } = useContext(PlayerContext)

  if(!youtubeUrl) return null

  // If we're able to extract the embedded URL then we open the spotify player
  // inline.  If we can't, just let the user go wherever the URL is to avoid
  // errors
  const handleClick = (e) => {
    const embeddedUrl = extractYoutubeEmbedUrl(youtubeUrl)
    if(embeddedUrl) {
      e.preventDefault()
      setActiveYoutubeUrl(embeddedUrl)
    }
  }

  return (
    <span className="me-2">
      <a href={youtubeUrl} onClick={handleClick} target="_blank">
        <FaIcon icon="youtube" /> { " " } YouTube
      </a>
    </span>
  )
}

export default YoutubePreview
