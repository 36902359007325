import React from 'react'

const YoutubePlayer = ({ youtubeUrl }) => {
  if(!youtubeUrl) return null

  return (
    <iframe
      width="100%"
      height="300"
      src={youtubeUrl}
      title=""
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
    )
}

export default YoutubePlayer
