export default function extractYoutubeEmbedUrl(url) {
  if (url.match(/https:\/\/www\.youtube\.com\/watch\?v=.*/)) {
    return addAutoPlayToYoutubeEmbedUrl(url.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'))
  }
  else if (url.match(/https:\/\/music\.youtube\.com\/watch\?v=.*/)) {
    return addAutoPlayToYoutubeEmbedUrl(url.replace('https://music.youtube.com/watch?v=', 'https://www.youtube.com/embed/'))
  }
  else {
    return null
  }
}

function addAutoPlayToYoutubeEmbedUrl(url) {
  if(url.includes('autoplay=1')) {
    return url
  }
  else if(url.includes('?')) {
    return `${url}&autoplay=1`
  }
  else {
    return `${url}?autoplay=1`
  }
}

