import React from 'react'

const SpotifyPlayer = ({ spotifyUrl }) => {
  if(!spotifyUrl) return null

  return (
    <iframe
      src={spotifyUrl}
      width="100%"
      height="300"
      frameBorder="0"
      allowtransparency="true"
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
      allow="autoplay *; encrypted-media *; clipboard-write"
    />
    )
}

export default SpotifyPlayer
