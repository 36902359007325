import React from 'react'
import PropTypes from 'prop-types'
import EventBooking from './EventBooking'
import GroupedEventBooking from './GroupedEventBooking'
import MiniSession from './MiniSession'
import LeadBooking from './LeadBooking'
import FranchiseBlockoutDateRange from './FranchiseBlockoutDateRange'
import StaffBlockoutDateRange from './StaffBlockoutDateRange'
import StaffDayUnavailable from './StaffDayUnavailable'
import SyncEvent from './SyncEvent'
import Appointment from './Appointment'

export default class CalendarEvent extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      type: PropTypes.string.isRequired
    }).isRequired
  }

  render() {
    const { event } = this.props

    switch(event.type) {
      case "lead-booking":
        return <LeadBooking {...this.props} />
      case "event-booking":
        return <EventBooking {...this.props} />
      case "grouped-event-booking":
        return <GroupedEventBooking {...this.props} />
      case "mini-session":
        return <MiniSession {...this.props} />
      case "franchise-blockout-date-range":
        return <FranchiseBlockoutDateRange {...this.props} />
      case "staff-blockout-date-range":
        return <StaffBlockoutDateRange {...this.props} />
      case "staff-day-unavailable":
        return <StaffDayUnavailable {...this.props} />
      case "synced-event":
        return <SyncEvent {...this.props} />
      case "appointment":
        return <Appointment {...this.props} />
      default: 
        return null
    }
  }
}

