import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, HelpBlock } from 'react-bootstrap'
import MusicBrowser from '../../ui/MusicBrowser'
import SongPreview from '../../ui/SongPreview'
import FaIcon from '../../../helpers/fa_icon'
import { observer } from "mobx-react"
import store from '../stores/store.js'
import client from '../../../helpers/client'
import { toJS } from 'mobx'

const SongField = observer(({ field, instance }) => {
  const { name, placeholder, uuid, hint, required, suggestionListId, allowCustomSongEntry } = field
  const currentInstance = instance || 0
  const songId = store.valueFor(uuid, "songId", currentInstance)
  const songName = store.valueFor(uuid, "songName", currentInstance)
  const songArtistName = store.valueFor(uuid, "songArtistName", currentInstance)
  const songAlbumName = store.valueFor(uuid, "songAlbumName", currentInstance)
  const songPreviewUrl = store.valueFor(uuid, "songPreviewUrl", currentInstance)
  const songAlbumUrl = store.valueFor(uuid, "songAlbumUrl", currentInstance)
  const durationSeconds = store.valueFor(uuid, "songDurationSeconds", currentInstance)
  const spotifyUrl = store.valueFor(uuid, "spotifyUrl", currentInstance)
  const appleMusicUrl = store.valueFor(uuid, "appleMusicUrl", currentInstance)
  const youtubeUrl = store.valueFor(uuid, "youtubeUrl", currentInstance)
  const soundCloudUrl = store.valueFor(uuid, "soundCloudUrl", currentInstance)
  const songProvider = store.valueFor(uuid, "songProvider", currentInstance)
  const songSuggestionListId = store.valueFor(uuid, "songSuggestionListId", currentInstance)
  const selectionNotes = store.valueFor(uuid, "selectionNotes", currentInstance)
  const publicNotes = store.valueFor(uuid, "publicNotes", currentInstance)
  const errorMessage = store.errorFor(uuid, "songId", currentInstance)
  const fieldEditingIsLocked = store.fieldEditingIsLocked(field)

  function setSong({ id, name, artistName, albumName, previewUrl, albumImageUrl, durationSeconds, provider, suggestionListId, selectionNotes, publicNotes, spotifyUrl, appleMusicUrl, youtubeUrl, soundCloudUrl}) {
    store.setValueFor(uuid, "songId", currentInstance, id)
    store.setValueFor(uuid, "songName", currentInstance, name)
    store.setValueFor(uuid, "songArtistName", currentInstance, artistName)
    store.setValueFor(uuid, "songAlbumName", currentInstance, albumName)
    store.setValueFor(uuid, "songPreviewUrl", currentInstance, previewUrl)
    store.setValueFor(uuid, "songAlbumUrl", currentInstance, albumImageUrl)
    store.setValueFor(uuid, "songDurationSeconds", currentInstance, durationSeconds)
    store.setValueFor(uuid, "songProvider", currentInstance, provider)
    store.setValueFor(uuid, "songSuggestionListId", currentInstance, suggestionListId)
    store.setValueFor(uuid, "selectionNotes", currentInstance, selectionNotes)
    store.setValueFor(uuid, "publicNotes", currentInstance, publicNotes)
    store.setValueFor(uuid, "spotifyUrl", currentInstance, spotifyUrl)
    store.setValueFor(uuid, "appleMusicUrl", currentInstance, appleMusicUrl)
    store.setValueFor(uuid, "youtubeUrl", currentInstance, youtubeUrl)
    store.setValueFor(uuid, "soundCloudUrl", currentInstance, soundCloudUrl)

    if(store.allowSave) {
      store.persistFormContent(null, true)
    }
  }

  return (
    <>
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        <SongPreview
          id={songId}
          name={songName}
          artistName={songArtistName}
          albumName={songAlbumName}
          albumImageUrl={songAlbumUrl}
          previewUrl={songPreviewUrl}
          durationSeconds={durationSeconds}
          provider={songProvider}
          suggestionListId={songSuggestionListId || suggestionListId}
          allowCustomSongEntry={allowCustomSongEntry}
          selectionNotes={selectionNotes}
          spotifyUrl={spotifyUrl}
          appleMusicUrl={appleMusicUrl}
          youtubeUrl={youtubeUrl}
          soundCloudUrl={soundCloudUrl}
          publicNotes={publicNotes}
          onUpdate={(store.editable && !fieldEditingIsLocked) ? setSong : null}
          isInvalid={errorMessage}
        />
        {!songId &&
          <div>
            <MusicBrowser
              onChange={setSong}
              label={<FaIcon icon="plus" text="Choose Song" />}
              suggestionListId={suggestionListId}
              allowCustomSongEntry={allowCustomSongEntry}
              disabled={!store.editable}
            />
          </div>
        }
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
        { hint && <Form.Text muted>{hint}</Form.Text > }
      </FormGroup>
    </>

  )
})

export default SongField
