import React from 'react'
import SongPreview from '../SongPreview'

const SongList = ({ songs, onSelect, suggestionListId }) => (
  <>
    { songs.map(song => (
      <SongPreview
        key={song.id}
        id={song.id}
        {...song.attributes}
        onAdd={onSelect}
        provider={song.attributes.provider}
        suggestionListId={song.attributes.suggestionListId || suggestionListId}
      />
    ))}
  </>
)

export default SongList
