import React, { useState, useEffect, forwardRef } from 'react'
import { buildApiClient } from '../../helpers/client'
import { addDays, subDays, format } from 'date-fns'
import DatePicker from 'react-datepicker'
import "./index.css"


const AppointmentsForDate = ({ apiKey, brandId, variant, dateFormat }) => {
  const [ready, setReady] = useState(false)
  const [activeDate, setActiveDate] = useState(new Date())
  const [appointments, setAppointments] = useState([])
  const client = buildApiClient(apiKey)

  useEffect(() => {
    loadAppointmentsForDate()
  }, [activeDate])

  async function loadAppointmentsForDate() {
    let params = {
      start_date: format(activeDate, 'yyyy-MM-dd'),
      end_date: format(activeDate, 'yyyy-MM-dd')
    }
    if(brandId) {
      params.brand_id = brandId
    }
    if(variant == "My Appointments") {
      params.staff_id = "my"
    }
    const url = `/api/v1/appointments?${new URLSearchParams(params)}`
    const response = await client.get(url)
    const appointments = response.data.data
    setAppointments(appointments)
    setReady(true)
  }

  function decrementDate(e) {
    e.preventDefault()
    setActiveDate(subDays(activeDate, 1))
  }

  function incrementDate(e) {
    e.preventDefault()
    setActiveDate(addDays(activeDate, 1))
  }

  if(!ready) {
    return null
  }

  const TitleInput = forwardRef(({ value, onClick }, ref) => {
    function handleClick(e) {
      e.preventDefault()
      onClick(e)
    }

    return (
      <a href="#" onClick={handleClick} ref={ref}>
        <h5>Appointments For { format(activeDate, dateFormat) }</h5>
      </a>
    )
  });

  return (
    <div className="widget__activity-for-date">
      <div className="d-flex align-items-baseline">
        <a href="#" onClick={decrementDate} className="me-2 subtle-edit">
          <i className="fa fa-chevron-left" />
        </a>
        <DatePicker
          name={name}
          customInput={<TitleInput />}
          onChange={setActiveDate}
          selected={activeDate}
          dateFormat={dateFormat}
        />
        <a href="#" onClick={incrementDate} className="ms-2 subtle-edit">
          <i className="fa fa-chevron-right" />
        </a>
      </div>

      { appointments.length > 0 ? (
        <div className="list-group list-group-flush">
          { appointments.map(appointment => (
            <a href={`/appointments/${appointment.id}`} className="list-group-item list-group-item-action" key={appointment.id}>
              <div>
                <span className="fw-medium">{ appointment.attributes.title }</span>
                <span className="badge text-bg-warning ms-1">Appointment</span>
              </div>
              <div className="text-muted">
                { appointment.attributes.formattedTimeRange }
              </div>
            </a>
          )) }
        </div>
      ) : (
        <div className="text-muted">No appointments for this date.</div>
      )}
    </div>
  )
}

export default AppointmentsForDate
