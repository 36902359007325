import React, { useState, useEffect } from 'react'
import SearchBar from './SearchBar'
import SongList from './SongList'
import client from '../../../helpers/client'
import useDebounce from '../../../helpers/useDebounce'

async function fetchSuggestions(suggestionListId) {
  const url = `/api/v1/suggestion_lists/${suggestionListId}`
  const response = await client.get(url)
  const suggestions = response.data.data
  return suggestions
}

async function searchSuggestions(text, suggestionData) {
  const suggestions = deserializeSuggestionData(suggestionData)

  return suggestions.filter(song => {
    const searchText = [song.attributes.name, song.attributes.artistName, song.attributes.albumName].join(" ").toLowerCase()
    return searchText.includes(text)
  })
}

function deserializeSuggestionData(suggestionData) {
  if(!suggestionData) return []
  return suggestionData.attributes.data
    .map(suggestionRecord => {
      return {
        id: suggestionRecord.id,
        attributes: suggestionRecord
      }
    })
}

const SuggestionSearch = ({ suggestionListId, searchText, setSearchText, onSelect }) => {
  const [ songs, setSongs ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ suggestionData, setSuggestionData ] = useState()
  const debouncedSearchValue = useDebounce(searchText, 500)

  useEffect(() => {
    loadSuggestions()
  }, [suggestionListId ])

  useEffect(() => {
    handleSearch()
  }, [debouncedSearchValue])

  async function loadSuggestions() {
    if(!suggestionListId) return
    setLoading(true)
    const suggestionData = await fetchSuggestions(suggestionListId)
    const songs = deserializeSuggestionData(suggestionData)
    setSuggestionData(suggestionData)
    setSongs(songs)
    setLoading(false)
  }

  async function handleSearch(e = null) {
    if(e) {
      e.preventDefault()
    }

    const songs = deserializeSuggestionData(suggestionData)
      .filter(song => {
        const songText = [song.attributes.name, song.attributes.artistName, song.attributes.albumName].join(" ").toLowerCase()
        return songText.includes(searchText.toLowerCase())
      })
    setSongs(songs)
  }

  if(!suggestionListId) return null

  return (
    <>
      <SearchBar
        loading={loading}
        onSearch={handleSearch}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <SongList
        songs={songs}
        onSelect={onSelect}
        suggestionListId={suggestionListId}
      />
    </>
  )
}

export default SuggestionSearch
