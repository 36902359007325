import React, { useState, useContext } from 'react'
import FaIcon from '../../../../helpers/fa_icon'
import PlayerContext from './PlayerContext'
import extractAppleMusicEmbedUrl from '../util/extractAppleMusicEmbedUrl'

const AppleMusicPreview = ({ appleMusicUrl }) => {
  const { setActiveAppleMusicUrl } = useContext(PlayerContext)

  if(!appleMusicUrl) return null

  // If we're able to extract the embedded URL then we open the spotify player
  // inline.  If we can't, just let the user go wherever the URL is to avoid
  // errors
  const handleClick = (e) => {
    const embeddedUrl = extractAppleMusicEmbedUrl(appleMusicUrl)
    if(embeddedUrl) {
      e.preventDefault()
      setActiveAppleMusicUrl(embeddedUrl)
    }
  }

  return (
    <span className="me-2">
      <a href={appleMusicUrl} onClick={handleClick} target="_blank">
        <FaIcon icon="apple" /> { " " } Apple Music
      </a>
    </span>
  )
}

export default AppleMusicPreview
