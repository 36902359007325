import React, { useState, useEffect } from 'react';
import { FormControl } from 'react-bootstrap'
import SearchResultList from './SearchResultList'
import client from '../../../helpers/client'
import useDebounce from '../../../helpers/useDebounce'

const SearchBox = ({ apiKey }) => {
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [searchResults, setSearchResults] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (debouncedSearchValue.length > 2) {
      loadSearchResults(debouncedSearchValue)
    }
    else {
      setSearchResults(null)
    }
  }, [debouncedSearchValue])

  async function loadSearchResults() {
    try {
      const params = new URLSearchParams({ api_key: apiKey, text: debouncedSearchValue })

      const response = await client.get(`/api/v1/global_search_results?${params}`)
      const searchResults = response.data.data
      setSearchResults(searchResults)
      setError(null)
    }
    catch (error) {
      const { data, status } = error.response
      const { errors } = data
      const errorMessage = status === 422 ? errors.join(", ") : "An error occurred while contacting the server. Please try again later."
      console.error(errorMessage)
      setSearchResults({})
      setError(errorMessage)
    }
  }

  return (
    <div className="global-search-widget-box">
      { error ?  <div className="alert alert-danger">{error}</div> : null }
      <FormControl
        placeholder="Search for bookings, proposals, leads, users and more..."
        name="global_search"
        onChange={e => setSearchValue(e.target.value)}
        value={searchValue}
        autoFocus
      />
      { searchResults ?
        <SearchResultList searchResults={searchResults} searchValue={searchValue} />
        :
        null
      }
    </div>
  )
}

export default SearchBox
