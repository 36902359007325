import React from 'react'
import SongPreview from '../SongPreview'
import CustomSong from './CustomSong'

const CurrentSongDisplay = ({ currentSong, editExtendedAttributes, onSelect, suggestionListId }) => {
  if(!currentSong) {
    return null
  }
  else if(currentSong.provider === 'custom' && !currentSong.suggestionListId) {
    return (
      <CustomSong
        currentSong={currentSong}
        onSelect={onSelect}
        editExtendedAttributes={editExtendedAttributes}
        suggestionListId={suggestionListId}
      />
    )
  }
  else {
    return (
      <SongPreview
        {...currentSong}
        key={currentSong.id}
        id={currentSong.id}
        onAdd={onSelect}
        onUpdate={null}
        editExtendedAttributes={editExtendedAttributes}
        showConfirmAdd
      />
    )
  }
}

export default CurrentSongDisplay
